@font-face {
  font-family: "ConduitITC";
  src: url("../../fonts/Conduit ITC Regular.otf");
}
@font-face {
  font-family: "ConduitITC";
  src: url("../../fonts/Conduit ITC Bold.otf");
  font-weight: bold;
  /*config its weight*/
}
@font-face {
  font-family: "ConduitITC";
  src: url("../../fonts/Conduit ITC Italic.otf");
  font-style: italic;
}
@font-face {
  font-family: "PassionOne";
  src: url("../../fonts/PassionOne-Regular.ttf");
}
@font-face {
  font-family: "PassionOne";
  src: url("../../fonts/PassionOne-Bold.ttf");
  font-weight: bold;
  /*config its weight*/
}
.flex {
  display: grid;
}
.slide-up {
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.list-select {
  font-size: 0.65rem;
  border: 1px solid #001529;
  color: black;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 6px;
  text-align: center;
  float: right;
  margin-right: 8px;
  background: none;
}
.list-select:hover {
  background-color: #001529;
  color: white;
  cursor: pointer;
}
.list-select:active {
  background-color: #003d75;
  color: white;
  cursor: pointer;
}
.list-cost {
  font-size: 0.65rem;
  border: 1px solid #001529;
  color: black;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 6px;
  text-align: center;
  float: right;
  margin-right: 8px;
  background: none;
}
.list-category {
  border-bottom: 1px solid lightgray !important;
  width: 100%;
  background-color: #d3d3d36e;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.list-category .icon {
  font-size: 1rem;
}
.list-category .name {
  font-weight: 600;
  font-size: 1rem;
  padding-left: 8px !important;
}
.mobile-faction .title .heading {
  text-align: center;
  background-color: var(--header-colour);
  padding-left: 8px;
}
.mobile-faction .title .heading .title {
  text-transform: uppercase;
  color: white;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  z-index: 2;
}
.mobile-faction .enhancements .heading {
  background-color: var(--header-colour);
  padding-left: 8px;
}
.mobile-faction .enhancements .heading .title {
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  z-index: 2;
}
.mobile-faction .stratagems .heading {
  background-color: var(--header-colour);
  padding-left: 8px;
}
.mobile-faction .stratagems .heading .title {
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  z-index: 2;
}
.mobile-icon-button span {
  font-size: 22px !important;
}
.mobile-menu .ant-list-item:hover,
.desktop-list .ant-list-item:hover {
  background-color: #e7e7e7;
}
.mobile-menu .ant-list-item.selected,
.desktop-list .ant-list-item.selected {
  background-color: #0070d6;
}
.mobile-menu .ant-list-item.selected span,
.desktop-list .ant-list-item.selected span {
  font-weight: 600;
  color: white;
}
.mobile-menu .ant-list-item.selected:hover,
.desktop-list .ant-list-item.selected:hover {
  background-color: #0079eb;
}
.mobile-menu .ant-list-item:active,
.desktop-list .ant-list-item:active {
  background-color: #0082fc;
}
.mobile-menu .ant-list-item:active span,
.desktop-list .ant-list-item:active span {
  font-weight: 600;
  color: white;
}
.mobile-menu .ant-list-item.disabled,
.desktop-list .ant-list-item.disabled {
  background-color: #f5f5f5;
}
.mobile-menu .ant-list-item.disabled span,
.desktop-list .ant-list-item.disabled span {
  font-weight: 200;
  color: #00000040;
}
.bottom-nav .bottom-nav-item {
  background-color: #001529;
  color: #a5a5a5;
  font-size: 1.5rem;
  transition: font-size 0.1s ease, color 0.1s ease;
}
.bottom-nav .bottom-nav-item.active {
  font-size: 1.7rem;
  color: white;
  background-color: #04223e;
}
.nav-menu-item {
  display: flex;
  justify-content: center;
  height: calc(64px);
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}
.nav-menu-item.selected {
  border-bottom: 3px solid #0070d6;
}
.nav-menu-item:hover {
  border-bottom: 3px solid white;
}
.welcome-background {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000aa;
}
.modal-background {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000aa;
}
.welcome-container {
  border-radius: 4px;
  width: 1000px;
  height: 780px;
  position: absolute;
  background: white;
  top: 45%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.whatsnew-container {
  border-radius: 4px;
  width: 800px;
  position: absolute;
  background: white;
  top: 45%;
  left: 50%;
  padding-bottom: 48px;
  transform: translateX(-50%) translateY(-50%);
}
.factionsettings-container {
  border-radius: 4px;
  width: 800px;
  position: absolute;
  background: white;
  top: 45%;
  left: 50%;
  padding-bottom: 0px;
  transform: translateX(-50%) translateY(-50%);
}
.welcome-cover {
  height: "510px";
}
.welcome-cover::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 90px;
  width: 100%;
  height: calc(100% - 180px);
  opacity: 0.05;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.labels {
  font-weight: 600;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.edit_heading {
  display: grid;
  background: #b6beba;
  grid-template-rows: 14px;
  vertical-align: middle;
  border-bottom: 2px solid #777273;
}
.edit_line {
  display: grid;
  background: #b6beba;
  grid-template-rows: 32px;
  vertical-align: middle;
  border-bottom: 2px solid #777273;
}
.weapon_edit_profile {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 11px;
}
.ganger_weapon_profile {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 11px;
}
.center {
  display: grid;
  place-items: center;
  justify-content: center;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.ant-form-small .ant-form-item {
  margin-bottom: 16px;
}
.tree-item-container.selected .tree-state {
  padding-left: 5px;
  padding-top: 2px;
  width: 24px;
}
.tree-item-container .tree-state {
  padding-left: 9px;
  padding-top: 2px;
  width: 24px;
}
.vertical-resizer {
  width: 2px;
  background-color: rgba(0, 0, 0, 0.06);
  transition: background-color 0.25s linear;
}
.vertical-resizer:hover {
  background-color: grey;
  width: 2px;
}
.horizontal-resizer {
  height: 2px;
  background-color: rgba(0, 0, 0, 0.06);
  transition: background-color 0.25s linear;
}
.horizontal-resizer:hover {
  background-color: grey;
  height: 2px;
}
.list-item .AS::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .AC::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .AdM::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AdM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .AE::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CA::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .AM::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CD::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CD.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .QT::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CSM::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CSM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .DG::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .DRU::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DRU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .GC::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .GK::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .HTL::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HTL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .QI::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .INQ::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/INQ.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .NEC::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/NEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .OA::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/OA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .ORK::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/ORK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .RaH::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RaH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .RT::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .SM::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/SM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .TAU::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TAU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .TS::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .TL::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .TYR::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TYR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .UN::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/UN.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHAC::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHAC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHBT::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHBA::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHBR::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHCA::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHCR::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHCF::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHDA::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHDW::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHEM::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHEX::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEX.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHES::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHES.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHFA::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHFH::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHFT::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHHG::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHHG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHIF::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHIH::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHLA::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHLA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHMW::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHME::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHME.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHMI::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHC0::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHC0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHNO::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHNO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHRA::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHRG::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHRS::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHSA::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHST::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHST.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHSO::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHSW::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHSP::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSP.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHTC::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHTK::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHUL::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHUL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHWS::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .CHWO::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .LGAL::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGAL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .LGBL::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGBL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .LGCB::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGCB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .LGEC::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .LGIW::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGIW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .LGNL::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGNL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .LGRC::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGRC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .LGWB::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .LGWE::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .HAR::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HAR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .WE::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/WE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .AoI::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AoI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .GSC::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GSC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.list-item .LoV::before {
  content: " ";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  right: 8px;
  margin-top: -4px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LoV.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k.no-icons .edit_heading .label .movement::after,
.data-basic.no-icons .edit_heading .label .movement::after {
  content: "M";
  font-size: 11px;
  display: block;
  margin-top: -2px;
}
.data-40k.no-icons .edit_heading .label .weaponskill::after,
.data-basic.no-icons .edit_heading .label .weaponskill::after {
  content: "WS";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .ballisticskill::after,
.data-basic.no-icons .edit_heading .label .ballisticskill::after {
  content: "BS";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .toughness::after,
.data-basic.no-icons .edit_heading .label .toughness::after {
  content: "T";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .wounds::after,
.data-basic.no-icons .edit_heading .label .wounds::after {
  content: "W";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .attacks::after,
.data-basic.no-icons .edit_heading .label .attacks::after {
  content: "A";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .leadership::after,
.data-basic.no-icons .edit_heading .label .leadership::after {
  content: "Ld";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .save::after,
.data-basic.no-icons .edit_heading .label .save::after {
  content: "Sv";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .inv::after,
.data-basic.no-icons .edit_heading .label .inv::after {
  content: "Inv";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .ap::after,
.data-basic.no-icons .edit_heading .label .ap::after {
  content: "AP";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .dmg::after,
.data-basic.no-icons .edit_heading .label .dmg::after {
  content: "D";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .range::after,
.data-basic.no-icons .edit_heading .label .range::after {
  content: "Rng";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .strength::after,
.data-basic.no-icons .edit_heading .label .strength::after {
  content: "S";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .type::after,
.data-basic.no-icons .edit_heading .label .type::after {
  content: "Type";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  margin-left: -8px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .assault-weapon::after,
.data-basic.no-icons .edit_heading .label .assault-weapon::after {
  content: "As";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .grenade::after,
.data-basic.no-icons .edit_heading .label .grenade::after {
  content: "Gr";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .dakka::after,
.data-basic.no-icons .edit_heading .label .dakka::after {
  content: "Dk";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .heavy-weapon::after,
.data-basic.no-icons .edit_heading .label .heavy-weapon::after {
  content: "Hv";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .melee::after,
.data-basic.no-icons .edit_heading .label .melee::after {
  content: "Me";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .pistol::after,
.data-basic.no-icons .edit_heading .label .pistol::after {
  content: "Ps";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .rapid-fire::after,
.data-basic.no-icons .edit_heading .label .rapid-fire::after {
  content: "Rf";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k.no-icons .edit_heading .label .user::after,
.data-basic.no-icons .edit_heading .label .user::after {
  content: "Us";
  font-size: 11px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k.icons .edit_heading .label .icon,
.data-basic.icons .edit_heading .label .icon {
  height: 11px;
  width: 11px;
  margin-right: 2px;
  margin-top: -2px;
  background-repeat: no-repeat;
}
.data-40k.icons .edit_heading .label .movement,
.data-basic.icons .edit_heading .label .movement {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Move.svg");
}
.data-40k.icons .edit_heading .label .weaponskill,
.data-basic.icons .edit_heading .label .weaponskill {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/WeaponSkill.svg");
}
.data-40k.icons .edit_heading .label .ballisticskill,
.data-basic.icons .edit_heading .label .ballisticskill {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/BallisticSkill.svg");
}
.data-40k.icons .edit_heading .label .toughness,
.data-basic.icons .edit_heading .label .toughness {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Toughness.svg");
}
.data-40k.icons .edit_heading .label .wounds,
.data-basic.icons .edit_heading .label .wounds {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Wounds.svg");
}
.data-40k.icons .edit_heading .label .attacks,
.data-basic.icons .edit_heading .label .attacks {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Attacks.svg");
}
.data-40k.icons .edit_heading .label .leadership,
.data-basic.icons .edit_heading .label .leadership {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Leadership.svg");
}
.data-40k.icons .edit_heading .label .save,
.data-basic.icons .edit_heading .label .save {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Save.svg");
}
.data-40k.icons .edit_heading .label .inv,
.data-basic.icons .edit_heading .label .inv {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Invuln.svg");
}
.data-40k.icons .edit_heading .label .ap,
.data-basic.icons .edit_heading .label .ap {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/ArmourPenetration.svg");
}
.data-40k.icons .edit_heading .label .dmg,
.data-basic.icons .edit_heading .label .dmg {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Damage.svg");
}
.data-40k.icons .edit_heading .label .range,
.data-basic.icons .edit_heading .label .range {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Range.svg");
}
.data-40k.icons .edit_heading .label .strength,
.data-basic.icons .edit_heading .label .strength {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Strength.svg");
}
.data-40k.icons .edit_heading .label .type,
.data-basic.icons .edit_heading .label .type {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Type.svg");
}
.data-40k.icons .edit_heading .label .assault-weapon,
.data-basic.icons .edit_heading .label .assault-weapon {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Assault.svg");
}
.data-40k.icons .edit_heading .label .grenade,
.data-basic.icons .edit_heading .label .grenade {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Grenade.svg");
}
.data-40k.icons .edit_heading .label .dakka,
.data-basic.icons .edit_heading .label .dakka {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/RapidFire.svg");
}
.data-40k.icons .edit_heading .label .heavy-weapon,
.data-basic.icons .edit_heading .label .heavy-weapon {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Heavy.svg");
}
.data-40k.icons .edit_heading .label .melee,
.data-basic.icons .edit_heading .label .melee {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Melee.svg");
}
.data-40k.icons .edit_heading .label .pistol,
.data-basic.icons .edit_heading .label .pistol {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Pistol.svg");
}
.data-40k.icons .edit_heading .label .rapid-fire,
.data-basic.icons .edit_heading .label .rapid-fire {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/RapidFire.svg");
}
.data-40k.icons .edit_heading .label .user,
.data-basic.icons .edit_heading .label .user {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/User.svg");
}
.data-40k .unit .page,
.data-basic .unit .page {
  width: inherit;
  display: grid;
  position: relative;
  padding: 8px;
  background-color: #d0d0d0;
}
.data-40k .unit .page.no-icons .movement::after,
.data-basic .unit .page.no-icons .movement::after {
  content: "M";
  font-size: 11px;
  display: block;
  margin-top: -2px;
}
.data-40k .unit .page.no-icons .weaponskill::after,
.data-basic .unit .page.no-icons .weaponskill::after {
  content: "WS";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .ballisticskill::after,
.data-basic .unit .page.no-icons .ballisticskill::after {
  content: "BS";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .toughness::after,
.data-basic .unit .page.no-icons .toughness::after {
  content: "T";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .wounds::after,
.data-basic .unit .page.no-icons .wounds::after {
  content: "W";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .attacks::after,
.data-basic .unit .page.no-icons .attacks::after {
  content: "A";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .leadership::after,
.data-basic .unit .page.no-icons .leadership::after {
  content: "Ld";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .save::after,
.data-basic .unit .page.no-icons .save::after {
  content: "Sv";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .inv::after,
.data-basic .unit .page.no-icons .inv::after {
  content: "Inv";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .ap::after,
.data-basic .unit .page.no-icons .ap::after {
  content: "AP";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .dmg::after,
.data-basic .unit .page.no-icons .dmg::after {
  content: "D";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .range::after,
.data-basic .unit .page.no-icons .range::after {
  content: "Rng";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .strength::after,
.data-basic .unit .page.no-icons .strength::after {
  content: "S";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .type::after,
.data-basic .unit .page.no-icons .type::after {
  content: "Type";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  margin-left: -8px;
  text-align: center;
}
.data-40k .unit .page.no-icons .assault-weapon::after,
.data-basic .unit .page.no-icons .assault-weapon::after {
  content: "As";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .grenade::after,
.data-basic .unit .page.no-icons .grenade::after {
  content: "Gr";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .dakka::after,
.data-basic .unit .page.no-icons .dakka::after {
  content: "Dk";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .heavy-weapon::after,
.data-basic .unit .page.no-icons .heavy-weapon::after {
  content: "Hv";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .melee::after,
.data-basic .unit .page.no-icons .melee::after {
  content: "Me";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .pistol::after,
.data-basic .unit .page.no-icons .pistol::after {
  content: "Ps";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .rapid-fire::after,
.data-basic .unit .page.no-icons .rapid-fire::after {
  content: "Rf";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k .unit .page.no-icons .user::after,
.data-basic .unit .page.no-icons .user::after {
  content: "Us";
  font-size: 11px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.data-40k .unit .page.icons .movement,
.data-basic .unit .page.icons .movement {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Move.svg");
}
.data-40k .unit .page.icons .weaponskill,
.data-basic .unit .page.icons .weaponskill {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/WeaponSkill.svg");
}
.data-40k .unit .page.icons .ballisticskill,
.data-basic .unit .page.icons .ballisticskill {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/BallisticSkill.svg");
}
.data-40k .unit .page.icons .toughness,
.data-basic .unit .page.icons .toughness {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Toughness.svg");
}
.data-40k .unit .page.icons .wounds,
.data-basic .unit .page.icons .wounds {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Wounds.svg");
}
.data-40k .unit .page.icons .attacks,
.data-basic .unit .page.icons .attacks {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Attacks.svg");
}
.data-40k .unit .page.icons .leadership,
.data-basic .unit .page.icons .leadership {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Leadership.svg");
}
.data-40k .unit .page.icons .save,
.data-basic .unit .page.icons .save {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Save.svg");
}
.data-40k .unit .page.icons .inv,
.data-basic .unit .page.icons .inv {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Invuln.svg");
}
.data-40k .unit .page.icons .ap,
.data-basic .unit .page.icons .ap {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/ArmourPenetration.svg");
}
.data-40k .unit .page.icons .dmg,
.data-basic .unit .page.icons .dmg {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Damage.svg");
}
.data-40k .unit .page.icons .range,
.data-basic .unit .page.icons .range {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Range.svg");
}
.data-40k .unit .page.icons .strength,
.data-basic .unit .page.icons .strength {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Strength.svg");
}
.data-40k .unit .page.icons .type,
.data-basic .unit .page.icons .type {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Type.svg");
}
.data-40k .unit .page.icons .assault-weapon,
.data-basic .unit .page.icons .assault-weapon {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Assault.svg");
}
.data-40k .unit .page.icons .grenade,
.data-basic .unit .page.icons .grenade {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Grenade.svg");
}
.data-40k .unit .page.icons .dakka,
.data-basic .unit .page.icons .dakka {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/RapidFire.svg");
}
.data-40k .unit .page.icons .heavy-weapon,
.data-basic .unit .page.icons .heavy-weapon {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Heavy.svg");
}
.data-40k .unit .page.icons .melee,
.data-basic .unit .page.icons .melee {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Melee.svg");
}
.data-40k .unit .page.icons .pistol,
.data-basic .unit .page.icons .pistol {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Pistol.svg");
}
.data-40k .unit .page.icons .rapid-fire,
.data-basic .unit .page.icons .rapid-fire {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/RapidFire.svg");
}
.data-40k .unit .page.icons .user,
.data-basic .unit .page.icons .user {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/User.svg");
}
.data-40k .unit .page.playingcard,
.data-basic .unit .page.playingcard {
  height: 8.89cm;
  width: 5.71cm;
}
.data-40k .unit .page.card,
.data-basic .unit .page.card {
  height: 10.8cm;
  width: 7.77cm;
}
.data-40k .unit .page.poker,
.data-basic .unit .page.poker {
  height: 8.89cm;
  width: 6.35cm;
}
.data-40k .unit .page.sheet,
.data-basic .unit .page.sheet {
  height: 209.5mm;
  width: 148mm;
}
.data-40k .unit .page.a4,
.data-basic .unit .page.a4 {
  height: 297mm;
  width: 210mm;
}
.data-40k .unit .page.letter,
.data-basic .unit .page.letter {
  height: 279mm;
  width: 216mm;
}
.data-40k .unit .page.letter-half,
.data-basic .unit .page.letter-half {
  height: 216mm;
  width: 139mm;
}
.data-40k .unit .page.secondary,
.data-basic .unit .page.secondary {
  height: 13.76cm;
  width: 7.77cm;
}
.data-40k .unit .page .value,
.data-basic .unit .page .value {
  vertical-align: middle;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  letter-spacing: 0px;
}
.data-40k .unit .page .frame,
.data-basic .unit .page .frame {
  display: block;
  height: 100%;
  width: 100%;
  background: black;
  border-radius: 13.5px;
  background-color: #e2e2dd;
  overflow: hidden;
}
.data-40k .unit .page .frame .background,
.data-basic .unit .page .frame .background {
  width: inherit;
}
.data-40k .unit .page .frame .AS::before,
.data-basic .unit .page .frame .AS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .AC::before,
.data-basic .unit .page .frame .AC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .AdM::before,
.data-basic .unit .page .frame .AdM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AdM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .AE::before,
.data-basic .unit .page .frame .AE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CA::before,
.data-basic .unit .page .frame .CA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .AM::before,
.data-basic .unit .page .frame .AM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CD::before,
.data-basic .unit .page .frame .CD::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CD.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .QT::before,
.data-basic .unit .page .frame .QT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CSM::before,
.data-basic .unit .page .frame .CSM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CSM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .DG::before,
.data-basic .unit .page .frame .DG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .DRU::before,
.data-basic .unit .page .frame .DRU::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DRU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .GC::before,
.data-basic .unit .page .frame .GC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .GK::before,
.data-basic .unit .page .frame .GK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .HTL::before,
.data-basic .unit .page .frame .HTL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HTL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .QI::before,
.data-basic .unit .page .frame .QI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .INQ::before,
.data-basic .unit .page .frame .INQ::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/INQ.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .NEC::before,
.data-basic .unit .page .frame .NEC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/NEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .OA::before,
.data-basic .unit .page .frame .OA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/OA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .ORK::before,
.data-basic .unit .page .frame .ORK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/ORK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .RaH::before,
.data-basic .unit .page .frame .RaH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RaH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .RT::before,
.data-basic .unit .page .frame .RT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .SM::before,
.data-basic .unit .page .frame .SM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/SM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .TAU::before,
.data-basic .unit .page .frame .TAU::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TAU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .TS::before,
.data-basic .unit .page .frame .TS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .TL::before,
.data-basic .unit .page .frame .TL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .TYR::before,
.data-basic .unit .page .frame .TYR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TYR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .UN::before,
.data-basic .unit .page .frame .UN::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/UN.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHAC::before,
.data-basic .unit .page .frame .CHAC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHAC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHBT::before,
.data-basic .unit .page .frame .CHBT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHBA::before,
.data-basic .unit .page .frame .CHBA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHBR::before,
.data-basic .unit .page .frame .CHBR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHCA::before,
.data-basic .unit .page .frame .CHCA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHCR::before,
.data-basic .unit .page .frame .CHCR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHCF::before,
.data-basic .unit .page .frame .CHCF::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHDA::before,
.data-basic .unit .page .frame .CHDA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHDW::before,
.data-basic .unit .page .frame .CHDW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHEM::before,
.data-basic .unit .page .frame .CHEM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHEX::before,
.data-basic .unit .page .frame .CHEX::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEX.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHES::before,
.data-basic .unit .page .frame .CHES::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHES.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHFA::before,
.data-basic .unit .page .frame .CHFA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHFH::before,
.data-basic .unit .page .frame .CHFH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHFT::before,
.data-basic .unit .page .frame .CHFT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHHG::before,
.data-basic .unit .page .frame .CHHG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHHG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHIF::before,
.data-basic .unit .page .frame .CHIF::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHIH::before,
.data-basic .unit .page .frame .CHIH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHLA::before,
.data-basic .unit .page .frame .CHLA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHLA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHMW::before,
.data-basic .unit .page .frame .CHMW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHME::before,
.data-basic .unit .page .frame .CHME::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHME.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHMI::before,
.data-basic .unit .page .frame .CHMI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHC0::before,
.data-basic .unit .page .frame .CHC0::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHC0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHNO::before,
.data-basic .unit .page .frame .CHNO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHNO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHRA::before,
.data-basic .unit .page .frame .CHRA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHRG::before,
.data-basic .unit .page .frame .CHRG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHRS::before,
.data-basic .unit .page .frame .CHRS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHSA::before,
.data-basic .unit .page .frame .CHSA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHST::before,
.data-basic .unit .page .frame .CHST::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHST.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHSO::before,
.data-basic .unit .page .frame .CHSO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHSW::before,
.data-basic .unit .page .frame .CHSW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHSP::before,
.data-basic .unit .page .frame .CHSP::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSP.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHTC::before,
.data-basic .unit .page .frame .CHTC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHTK::before,
.data-basic .unit .page .frame .CHTK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHUL::before,
.data-basic .unit .page .frame .CHUL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHUL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHWS::before,
.data-basic .unit .page .frame .CHWS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .CHWO::before,
.data-basic .unit .page .frame .CHWO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .LGAL::before,
.data-basic .unit .page .frame .LGAL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGAL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .LGBL::before,
.data-basic .unit .page .frame .LGBL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGBL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .LGCB::before,
.data-basic .unit .page .frame .LGCB::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGCB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .LGEC::before,
.data-basic .unit .page .frame .LGEC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .LGIW::before,
.data-basic .unit .page .frame .LGIW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGIW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .LGNL::before,
.data-basic .unit .page .frame .LGNL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGNL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .LGRC::before,
.data-basic .unit .page .frame .LGRC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGRC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .LGWB::before,
.data-basic .unit .page .frame .LGWB::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .LGWE::before,
.data-basic .unit .page .frame .LGWE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .HAR::before,
.data-basic .unit .page .frame .HAR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HAR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .WE::before,
.data-basic .unit .page .frame .WE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/WE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .AoI::before,
.data-basic .unit .page .frame .AoI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AoI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .GSC::before,
.data-basic .unit .page .frame .GSC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GSC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .LoV::before,
.data-basic .unit .page .frame .LoV::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LoV.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .AM::before,
.data-basic .unit .page .frame .AM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 55%;
  width: 100%;
  transform: translateY(-75px);
  height: 85px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .basic::before,
.data-basic .unit .page .frame .basic::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .unit .page .frame .header,
.data-basic .unit .page .frame .header {
  display: block;
  height: 45px;
  margin: 0 8px;
}
.data-40k .unit .page .frame .header .name,
.data-basic .unit .page .frame .header .name {
  float: left;
  width: calc(100% - 44px);
  min-height: 35px;
  padding-top: 8px;
  line-height: 15px;
  text-transform: uppercase;
  vertical-align: middle;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -1px;
  border-bottom: 2px solid #000;
}
.data-40k .unit .page .frame .header .role,
.data-basic .unit .page .frame .header .role {
  position: relative;
  float: left;
  width: 35px;
  height: 35px;
  padding-top: 8px;
  margin-right: 8px;
}
.data-40k .unit .page .frame .header .role div,
.data-basic .unit .page .frame .header .role div {
  height: 35px;
  width: 35px;
  position: absolute;
  bottom: 0;
}
.data-40k .unit .page .frame .header .role .hq,
.data-basic .unit .page .frame .header .role .hq {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/HQ.svg");
}
.data-40k .unit .page .frame .header .role .troops,
.data-basic .unit .page .frame .header .role .troops {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Troops.svg");
}
.data-40k .unit .page .frame .header .role .elites,
.data-basic .unit .page .frame .header .role .elites {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Elites.svg");
}
.data-40k .unit .page .frame .header .role .fast-attack,
.data-basic .unit .page .frame .header .role .fast-attack {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Fast-Attack.svg");
}
.data-40k .unit .page .frame .header .role .dedicated-transport,
.data-basic .unit .page .frame .header .role .dedicated-transport {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Dedicated-Transport.svg");
}
.data-40k .unit .page .frame .header .role .flyer,
.data-basic .unit .page .frame .header .role .flyer {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Flyer.svg");
}
.data-40k .unit .page .frame .header .role .heavy-support,
.data-basic .unit .page .frame .header .role .heavy-support {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Heavy-Support.svg");
}
.data-40k .unit .page .frame .header .role .fortification,
.data-basic .unit .page .frame .header .role .fortification {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Fortification.svg");
}
.data-40k .unit .page .frame .header .role .lord-of-war,
.data-basic .unit .page .frame .header .role .lord-of-war {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Lord-of-War.svg");
}
.data-40k .unit .page .frame .header .role .unknown,
.data-basic .unit .page .frame .header .role .unknown {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Unknown.svg");
}
.data-40k .unit .page .frame .description,
.data-basic .unit .page .frame .description {
  background: #dee3e0;
  margin: 4px 5px;
  padding: 0 4px;
  vertical-align: middle;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 700px;
  text-align: justify;
  letter-spacing: 0px;
  word-spacing: -1px;
  line-height: 14px;
}
.data-40k .unit .page .frame .description table,
.data-basic .unit .page .frame .description table {
  width: 100%;
}
.data-40k .unit .page .frame .description table th,
.data-basic .unit .page .frame .description table th {
  border: 1px solid #777273;
  padding: 4px;
  background-color: #b6beba;
}
.data-40k .unit .page .frame .description table td,
.data-basic .unit .page .frame .description table td {
  border: 1px solid #777273;
  padding: 4px;
}
.data-40k .unit .page .frame .description p,
.data-basic .unit .page .frame .description p {
  margin-bottom: 0;
}
.data-40k .unit .page .frame .abilities,
.data-basic .unit .page .frame .abilities {
  overflow: hidden;
  text-overflow: ellipsis;
}
.data-40k .unit .page .frame .profile,
.data-basic .unit .page .frame .profile {
  border-bottom: 2px solid #777273;
  margin: 0 8px;
}
.data-40k .unit .page .frame .profile .weapon_name,
.data-basic .unit .page .frame .profile .weapon_name {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
}
.data-40k .unit .page .frame .profile .weapon.nested,
.data-basic .unit .page .frame .profile .weapon.nested {
  padding-left: 8px;
}
.data-40k .unit .page .frame .profile .weapon:nth-of-type(even),
.data-basic .unit .page .frame .profile .weapon:nth-of-type(even) {
  background: #dee3e0;
}
.data-40k .unit .page .frame .profile .weapon:nth-of-type(even) + .weapon_desc,
.data-basic .unit .page .frame .profile .weapon:nth-of-type(even) + .weapon_desc {
  background: #dee3e0;
}
.data-40k .unit .page .frame .profile .weapon:nth-of-type(odd) + .weapon_desc,
.data-basic .unit .page .frame .profile .weapon:nth-of-type(odd) + .weapon_desc {
  background: #fff;
}
.data-40k .unit .page .frame .profile .weapon:nth-of-type(odd),
.data-basic .unit .page .frame .profile .weapon:nth-of-type(odd) {
  background: #fff;
}
.data-40k .unit .page .frame .profile .weapon .weapon_profile,
.data-basic .unit .page .frame .profile .weapon .weapon_profile {
  display: grid;
  grid-template-columns: 4fr 2fr 2fr 1fr 1fr 1fr;
  font-size: 11px;
}
.data-40k .unit .page .frame .profile .weapon .weapon_profile .weapon-icon,
.data-basic .unit .page .frame .profile .weapon .weapon_profile .weapon-icon {
  height: 11px;
  width: 11px;
  margin-right: 2px;
  margin-top: -2px;
  background-repeat: no-repeat;
}
.data-40k .unit .page .frame .profile .weapon .weapon_desc,
.data-basic .unit .page .frame .profile .weapon .weapon_desc {
  padding: 0 12px;
  margin-bottom: 4px;
  vertical-align: middle;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 700px;
  text-align: justify;
  letter-spacing: 0px;
  word-spacing: 0px;
  line-height: 14px;
}
.data-40k .unit .page .frame .profile .weapon .weapon_desc p,
.data-basic .unit .page .frame .profile .weapon .weapon_desc p {
  margin-bottom: 0px;
}
.data-40k .unit .page .frame .profile .statline,
.data-basic .unit .page .frame .profile .statline {
  display: grid;
  width: 9fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  text-align: centre;
  vertical-align: middle;
  font-weight: 600;
}
.data-40k .unit .page .frame .profile .statline:nth-child(even),
.data-basic .unit .page .frame .profile .statline:nth-child(even) {
  background: #dee3e0;
}
.data-40k .unit .page .frame .profile .statline:nth-child(odd),
.data-basic .unit .page .frame .profile .statline:nth-child(odd) {
  background: #fff;
}
.data-40k .unit .page .frame .labels,
.data-basic .unit .page .frame .labels {
  font-weight: 600;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.data-40k .unit .page .frame .labels .label div,
.data-basic .unit .page .frame .labels .label div {
  background-position: center;
}
.data-40k .unit .page .frame .weapons,
.data-basic .unit .page .frame .weapons {
  grid-template-columns: 4fr 2fr 2fr 1fr 1fr 1fr;
  font-size: 12px;
}
.data-40k .unit .page .frame .heading,
.data-basic .unit .page .frame .heading {
  display: grid;
  background: #b6beba;
  margin: 4px 8px 0 8px;
  grid-template-rows: 14px;
  vertical-align: middle;
  border-bottom: 2px solid #777273;
}
.data-40k .unit .page .frame .heading .icon,
.data-basic .unit .page .frame .heading .icon {
  display: block;
  height: 11px;
  width: 11px;
  margin: 0 auto;
  background-repeat: no-repeat;
}
.data-40k .unit .page .frame .footer,
.data-basic .unit .page .frame .footer {
  position: absolute;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
  background: #e2e2dd;
  z-index: 10;
  bottom: 0;
  transform: translateX(-50%) translateY(-10px);
  left: 50%;
  width: calc(100% - 32px);
}
.data-40k .stratagem .page,
.data-basic .stratagem .page {
  width: inherit;
  display: grid;
  position: relative;
  padding: 8px;
  background-color: #d0d0d0;
}
.data-40k .stratagem .page.playingcard,
.data-basic .stratagem .page.playingcard {
  height: 8.89cm;
  width: 5.71cm;
}
.data-40k .stratagem .page.card,
.data-basic .stratagem .page.card {
  height: 10.8cm;
  width: 7.77cm;
}
.data-40k .stratagem .page.poker,
.data-basic .stratagem .page.poker {
  height: 8.89cm;
  width: 6.35cm;
}
.data-40k .stratagem .page.sheet,
.data-basic .stratagem .page.sheet {
  height: 209.5mm;
  width: 148mm;
}
.data-40k .stratagem .page.a4,
.data-basic .stratagem .page.a4 {
  height: 297mm;
  width: 210mm;
}
.data-40k .stratagem .page.letter,
.data-basic .stratagem .page.letter {
  height: 279mm;
  width: 216mm;
}
.data-40k .stratagem .page.letter-half,
.data-basic .stratagem .page.letter-half {
  height: 216mm;
  width: 139mm;
}
.data-40k .stratagem .page.secondary,
.data-basic .stratagem .page.secondary {
  height: 13.76cm;
  width: 7.77cm;
}
.data-40k .stratagem .page .frame,
.data-basic .stratagem .page .frame {
  display: block;
  height: 100%;
  width: 100%;
  background: black;
  border-radius: 13.5px;
  background-color: #e2e2dd;
  overflow: hidden;
}
.data-40k .stratagem .page .frame .background,
.data-basic .stratagem .page .frame .background {
  width: inherit;
}
.data-40k .stratagem .page .frame .AS::before,
.data-basic .stratagem .page .frame .AS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .AC::before,
.data-basic .stratagem .page .frame .AC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .AdM::before,
.data-basic .stratagem .page .frame .AdM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AdM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .AE::before,
.data-basic .stratagem .page .frame .AE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CA::before,
.data-basic .stratagem .page .frame .CA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .AM::before,
.data-basic .stratagem .page .frame .AM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CD::before,
.data-basic .stratagem .page .frame .CD::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CD.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .QT::before,
.data-basic .stratagem .page .frame .QT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CSM::before,
.data-basic .stratagem .page .frame .CSM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CSM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .DG::before,
.data-basic .stratagem .page .frame .DG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .DRU::before,
.data-basic .stratagem .page .frame .DRU::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DRU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .GC::before,
.data-basic .stratagem .page .frame .GC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .GK::before,
.data-basic .stratagem .page .frame .GK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .HTL::before,
.data-basic .stratagem .page .frame .HTL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HTL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .QI::before,
.data-basic .stratagem .page .frame .QI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .INQ::before,
.data-basic .stratagem .page .frame .INQ::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/INQ.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .NEC::before,
.data-basic .stratagem .page .frame .NEC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/NEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .OA::before,
.data-basic .stratagem .page .frame .OA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/OA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .ORK::before,
.data-basic .stratagem .page .frame .ORK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/ORK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .RaH::before,
.data-basic .stratagem .page .frame .RaH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RaH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .RT::before,
.data-basic .stratagem .page .frame .RT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .SM::before,
.data-basic .stratagem .page .frame .SM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/SM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .TAU::before,
.data-basic .stratagem .page .frame .TAU::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TAU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .TS::before,
.data-basic .stratagem .page .frame .TS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .TL::before,
.data-basic .stratagem .page .frame .TL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .TYR::before,
.data-basic .stratagem .page .frame .TYR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TYR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .UN::before,
.data-basic .stratagem .page .frame .UN::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/UN.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHAC::before,
.data-basic .stratagem .page .frame .CHAC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHAC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHBT::before,
.data-basic .stratagem .page .frame .CHBT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHBA::before,
.data-basic .stratagem .page .frame .CHBA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHBR::before,
.data-basic .stratagem .page .frame .CHBR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHCA::before,
.data-basic .stratagem .page .frame .CHCA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHCR::before,
.data-basic .stratagem .page .frame .CHCR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHCF::before,
.data-basic .stratagem .page .frame .CHCF::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHDA::before,
.data-basic .stratagem .page .frame .CHDA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHDW::before,
.data-basic .stratagem .page .frame .CHDW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHEM::before,
.data-basic .stratagem .page .frame .CHEM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHEX::before,
.data-basic .stratagem .page .frame .CHEX::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEX.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHES::before,
.data-basic .stratagem .page .frame .CHES::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHES.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHFA::before,
.data-basic .stratagem .page .frame .CHFA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHFH::before,
.data-basic .stratagem .page .frame .CHFH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHFT::before,
.data-basic .stratagem .page .frame .CHFT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHHG::before,
.data-basic .stratagem .page .frame .CHHG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHHG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHIF::before,
.data-basic .stratagem .page .frame .CHIF::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHIH::before,
.data-basic .stratagem .page .frame .CHIH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHLA::before,
.data-basic .stratagem .page .frame .CHLA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHLA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHMW::before,
.data-basic .stratagem .page .frame .CHMW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHME::before,
.data-basic .stratagem .page .frame .CHME::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHME.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHMI::before,
.data-basic .stratagem .page .frame .CHMI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHC0::before,
.data-basic .stratagem .page .frame .CHC0::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHC0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHNO::before,
.data-basic .stratagem .page .frame .CHNO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHNO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHRA::before,
.data-basic .stratagem .page .frame .CHRA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHRG::before,
.data-basic .stratagem .page .frame .CHRG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHRS::before,
.data-basic .stratagem .page .frame .CHRS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHSA::before,
.data-basic .stratagem .page .frame .CHSA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHST::before,
.data-basic .stratagem .page .frame .CHST::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHST.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHSO::before,
.data-basic .stratagem .page .frame .CHSO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHSW::before,
.data-basic .stratagem .page .frame .CHSW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHSP::before,
.data-basic .stratagem .page .frame .CHSP::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSP.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHTC::before,
.data-basic .stratagem .page .frame .CHTC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHTK::before,
.data-basic .stratagem .page .frame .CHTK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHUL::before,
.data-basic .stratagem .page .frame .CHUL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHUL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHWS::before,
.data-basic .stratagem .page .frame .CHWS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .CHWO::before,
.data-basic .stratagem .page .frame .CHWO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .LGAL::before,
.data-basic .stratagem .page .frame .LGAL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGAL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .LGBL::before,
.data-basic .stratagem .page .frame .LGBL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGBL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .LGCB::before,
.data-basic .stratagem .page .frame .LGCB::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGCB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .LGEC::before,
.data-basic .stratagem .page .frame .LGEC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .LGIW::before,
.data-basic .stratagem .page .frame .LGIW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGIW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .LGNL::before,
.data-basic .stratagem .page .frame .LGNL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGNL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .LGRC::before,
.data-basic .stratagem .page .frame .LGRC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGRC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .LGWB::before,
.data-basic .stratagem .page .frame .LGWB::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .LGWE::before,
.data-basic .stratagem .page .frame .LGWE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .HAR::before,
.data-basic .stratagem .page .frame .HAR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HAR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .WE::before,
.data-basic .stratagem .page .frame .WE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/WE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .AoI::before,
.data-basic .stratagem .page .frame .AoI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AoI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .GSC::before,
.data-basic .stratagem .page .frame .GSC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GSC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .LoV::before,
.data-basic .stratagem .page .frame .LoV::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LoV.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .AM::before,
.data-basic .stratagem .page .frame .AM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 55%;
  width: 100%;
  transform: translateY(-75px);
  height: 85px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .basic::before,
.data-basic .stratagem .page .frame .basic::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .stratagem .page .frame .footer,
.data-basic .stratagem .page .frame .footer {
  border-top: 2px solid #000;
  position: absolute;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  bottom: 8px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
  background: #e2e2dd;
  z-index: 10;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 4px;
  width: calc(100% - 30px);
  left: 0;
}
.data-40k .stratagem .page .frame .header,
.data-basic .stratagem .page .frame .header {
  display: block;
  height: auto;
  margin: 0 8px;
}
.data-40k .stratagem .page .frame .type,
.data-basic .stratagem .page .frame .type {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 2px solid #000;
  text-transform: uppercase;
  text-align: center;
  margin-top: 8px;
}
.data-40k .stratagem .page .frame .name,
.data-basic .stratagem .page .frame .name {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin-top: 8px;
}
.data-40k .stratagem .page .frame .description,
.data-basic .stratagem .page .frame .description {
  margin: 8px 8px;
  margin-top: 4px;
  padding: 0 4px;
  vertical-align: middle;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  text-align: justify;
  letter-spacing: 0px;
  line-height: 14px;
}
.data-40k .stratagem .page .frame .description p,
.data-basic .stratagem .page .frame .description p {
  margin-bottom: 4px;
}
.data-40k .stratagem .page .frame .description ul,
.data-basic .stratagem .page .frame .description ul {
  padding-left: 16px;
}
.data-40k .stratagem .page .frame .description ul p,
.data-basic .stratagem .page .frame .description ul p {
  margin-bottom: 2px;
}
.data-40k .secondary .page,
.data-basic .secondary .page {
  width: inherit;
  display: grid;
  position: relative;
  padding: 8px;
  background-color: #d0d0d0;
}
.data-40k .secondary .page.playingcard,
.data-basic .secondary .page.playingcard {
  height: 8.89cm;
  width: 5.71cm;
}
.data-40k .secondary .page.card,
.data-basic .secondary .page.card {
  height: 10.8cm;
  width: 7.77cm;
}
.data-40k .secondary .page.poker,
.data-basic .secondary .page.poker {
  height: 8.89cm;
  width: 6.35cm;
}
.data-40k .secondary .page.sheet,
.data-basic .secondary .page.sheet {
  height: 209.5mm;
  width: 148mm;
}
.data-40k .secondary .page.a4,
.data-basic .secondary .page.a4 {
  height: 297mm;
  width: 210mm;
}
.data-40k .secondary .page.letter,
.data-basic .secondary .page.letter {
  height: 279mm;
  width: 216mm;
}
.data-40k .secondary .page.letter-half,
.data-basic .secondary .page.letter-half {
  height: 216mm;
  width: 139mm;
}
.data-40k .secondary .page.secondary,
.data-basic .secondary .page.secondary {
  height: 13.76cm;
  width: 7.77cm;
}
.data-40k .secondary .page .frame,
.data-basic .secondary .page .frame {
  display: block;
  height: 100%;
  width: 100%;
  background: black;
  background-color: #e2e2dd;
  overflow: hidden;
  border-radius: 13.5px;
}
.data-40k .secondary .page .frame .background,
.data-basic .secondary .page .frame .background {
  width: inherit;
}
.data-40k .secondary .page .frame .AS::before,
.data-basic .secondary .page .frame .AS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_AS::before,
.data-basic .secondary .page .frame .secondary_icon_AS::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .AC::before,
.data-basic .secondary .page .frame .AC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_AC::before,
.data-basic .secondary .page .frame .secondary_icon_AC::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .AdM::before,
.data-basic .secondary .page .frame .AdM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AdM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_AdM::before,
.data-basic .secondary .page .frame .secondary_icon_AdM::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AdM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .AE::before,
.data-basic .secondary .page .frame .AE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_AE::before,
.data-basic .secondary .page .frame .secondary_icon_AE::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CA::before,
.data-basic .secondary .page .frame .CA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CA::before,
.data-basic .secondary .page .frame .secondary_icon_CA::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .AM::before,
.data-basic .secondary .page .frame .AM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_AM::before,
.data-basic .secondary .page .frame .secondary_icon_AM::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CD::before,
.data-basic .secondary .page .frame .CD::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CD.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CD::before,
.data-basic .secondary .page .frame .secondary_icon_CD::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CD.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .QT::before,
.data-basic .secondary .page .frame .QT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_QT::before,
.data-basic .secondary .page .frame .secondary_icon_QT::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CSM::before,
.data-basic .secondary .page .frame .CSM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CSM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CSM::before,
.data-basic .secondary .page .frame .secondary_icon_CSM::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CSM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .DG::before,
.data-basic .secondary .page .frame .DG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_DG::before,
.data-basic .secondary .page .frame .secondary_icon_DG::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .DRU::before,
.data-basic .secondary .page .frame .DRU::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DRU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_DRU::before,
.data-basic .secondary .page .frame .secondary_icon_DRU::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DRU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .GC::before,
.data-basic .secondary .page .frame .GC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_GC::before,
.data-basic .secondary .page .frame .secondary_icon_GC::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .GK::before,
.data-basic .secondary .page .frame .GK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_GK::before,
.data-basic .secondary .page .frame .secondary_icon_GK::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .HTL::before,
.data-basic .secondary .page .frame .HTL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HTL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_HTL::before,
.data-basic .secondary .page .frame .secondary_icon_HTL::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HTL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .QI::before,
.data-basic .secondary .page .frame .QI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_QI::before,
.data-basic .secondary .page .frame .secondary_icon_QI::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .INQ::before,
.data-basic .secondary .page .frame .INQ::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/INQ.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_INQ::before,
.data-basic .secondary .page .frame .secondary_icon_INQ::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/INQ.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .NEC::before,
.data-basic .secondary .page .frame .NEC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/NEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_NEC::before,
.data-basic .secondary .page .frame .secondary_icon_NEC::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/NEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .OA::before,
.data-basic .secondary .page .frame .OA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/OA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_OA::before,
.data-basic .secondary .page .frame .secondary_icon_OA::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/OA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .ORK::before,
.data-basic .secondary .page .frame .ORK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/ORK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_ORK::before,
.data-basic .secondary .page .frame .secondary_icon_ORK::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/ORK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .RaH::before,
.data-basic .secondary .page .frame .RaH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RaH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_RaH::before,
.data-basic .secondary .page .frame .secondary_icon_RaH::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RaH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .RT::before,
.data-basic .secondary .page .frame .RT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_RT::before,
.data-basic .secondary .page .frame .secondary_icon_RT::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .SM::before,
.data-basic .secondary .page .frame .SM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/SM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_SM::before,
.data-basic .secondary .page .frame .secondary_icon_SM::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/SM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .TAU::before,
.data-basic .secondary .page .frame .TAU::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TAU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_TAU::before,
.data-basic .secondary .page .frame .secondary_icon_TAU::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TAU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .TS::before,
.data-basic .secondary .page .frame .TS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_TS::before,
.data-basic .secondary .page .frame .secondary_icon_TS::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .TL::before,
.data-basic .secondary .page .frame .TL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_TL::before,
.data-basic .secondary .page .frame .secondary_icon_TL::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .TYR::before,
.data-basic .secondary .page .frame .TYR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TYR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_TYR::before,
.data-basic .secondary .page .frame .secondary_icon_TYR::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TYR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .UN::before,
.data-basic .secondary .page .frame .UN::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/UN.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_UN::before,
.data-basic .secondary .page .frame .secondary_icon_UN::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/UN.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHAC::before,
.data-basic .secondary .page .frame .CHAC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHAC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHAC::before,
.data-basic .secondary .page .frame .secondary_icon_CHAC::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHAC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHBT::before,
.data-basic .secondary .page .frame .CHBT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHBT::before,
.data-basic .secondary .page .frame .secondary_icon_CHBT::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHBA::before,
.data-basic .secondary .page .frame .CHBA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHBA::before,
.data-basic .secondary .page .frame .secondary_icon_CHBA::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHBR::before,
.data-basic .secondary .page .frame .CHBR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHBR::before,
.data-basic .secondary .page .frame .secondary_icon_CHBR::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHCA::before,
.data-basic .secondary .page .frame .CHCA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHCA::before,
.data-basic .secondary .page .frame .secondary_icon_CHCA::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHCR::before,
.data-basic .secondary .page .frame .CHCR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHCR::before,
.data-basic .secondary .page .frame .secondary_icon_CHCR::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHCF::before,
.data-basic .secondary .page .frame .CHCF::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHCF::before,
.data-basic .secondary .page .frame .secondary_icon_CHCF::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHDA::before,
.data-basic .secondary .page .frame .CHDA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHDA::before,
.data-basic .secondary .page .frame .secondary_icon_CHDA::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHDW::before,
.data-basic .secondary .page .frame .CHDW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHDW::before,
.data-basic .secondary .page .frame .secondary_icon_CHDW::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHEM::before,
.data-basic .secondary .page .frame .CHEM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHEM::before,
.data-basic .secondary .page .frame .secondary_icon_CHEM::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHEX::before,
.data-basic .secondary .page .frame .CHEX::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEX.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHEX::before,
.data-basic .secondary .page .frame .secondary_icon_CHEX::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEX.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHES::before,
.data-basic .secondary .page .frame .CHES::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHES.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHES::before,
.data-basic .secondary .page .frame .secondary_icon_CHES::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHES.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHFA::before,
.data-basic .secondary .page .frame .CHFA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHFA::before,
.data-basic .secondary .page .frame .secondary_icon_CHFA::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHFH::before,
.data-basic .secondary .page .frame .CHFH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHFH::before,
.data-basic .secondary .page .frame .secondary_icon_CHFH::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHFT::before,
.data-basic .secondary .page .frame .CHFT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHFT::before,
.data-basic .secondary .page .frame .secondary_icon_CHFT::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHHG::before,
.data-basic .secondary .page .frame .CHHG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHHG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHHG::before,
.data-basic .secondary .page .frame .secondary_icon_CHHG::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHHG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHIF::before,
.data-basic .secondary .page .frame .CHIF::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHIF::before,
.data-basic .secondary .page .frame .secondary_icon_CHIF::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHIH::before,
.data-basic .secondary .page .frame .CHIH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHIH::before,
.data-basic .secondary .page .frame .secondary_icon_CHIH::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHLA::before,
.data-basic .secondary .page .frame .CHLA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHLA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHLA::before,
.data-basic .secondary .page .frame .secondary_icon_CHLA::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHLA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHMW::before,
.data-basic .secondary .page .frame .CHMW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHMW::before,
.data-basic .secondary .page .frame .secondary_icon_CHMW::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHME::before,
.data-basic .secondary .page .frame .CHME::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHME.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHME::before,
.data-basic .secondary .page .frame .secondary_icon_CHME::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHME.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHMI::before,
.data-basic .secondary .page .frame .CHMI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHMI::before,
.data-basic .secondary .page .frame .secondary_icon_CHMI::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHC0::before,
.data-basic .secondary .page .frame .CHC0::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHC0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHC0::before,
.data-basic .secondary .page .frame .secondary_icon_CHC0::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHC0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHNO::before,
.data-basic .secondary .page .frame .CHNO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHNO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHNO::before,
.data-basic .secondary .page .frame .secondary_icon_CHNO::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHNO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHRA::before,
.data-basic .secondary .page .frame .CHRA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHRA::before,
.data-basic .secondary .page .frame .secondary_icon_CHRA::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHRG::before,
.data-basic .secondary .page .frame .CHRG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHRG::before,
.data-basic .secondary .page .frame .secondary_icon_CHRG::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHRS::before,
.data-basic .secondary .page .frame .CHRS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHRS::before,
.data-basic .secondary .page .frame .secondary_icon_CHRS::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHSA::before,
.data-basic .secondary .page .frame .CHSA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHSA::before,
.data-basic .secondary .page .frame .secondary_icon_CHSA::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHST::before,
.data-basic .secondary .page .frame .CHST::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHST.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHST::before,
.data-basic .secondary .page .frame .secondary_icon_CHST::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHST.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHSO::before,
.data-basic .secondary .page .frame .CHSO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHSO::before,
.data-basic .secondary .page .frame .secondary_icon_CHSO::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHSW::before,
.data-basic .secondary .page .frame .CHSW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHSW::before,
.data-basic .secondary .page .frame .secondary_icon_CHSW::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHSP::before,
.data-basic .secondary .page .frame .CHSP::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSP.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHSP::before,
.data-basic .secondary .page .frame .secondary_icon_CHSP::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSP.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHTC::before,
.data-basic .secondary .page .frame .CHTC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHTC::before,
.data-basic .secondary .page .frame .secondary_icon_CHTC::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHTK::before,
.data-basic .secondary .page .frame .CHTK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHTK::before,
.data-basic .secondary .page .frame .secondary_icon_CHTK::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHUL::before,
.data-basic .secondary .page .frame .CHUL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHUL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHUL::before,
.data-basic .secondary .page .frame .secondary_icon_CHUL::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHUL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHWS::before,
.data-basic .secondary .page .frame .CHWS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHWS::before,
.data-basic .secondary .page .frame .secondary_icon_CHWS::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .CHWO::before,
.data-basic .secondary .page .frame .CHWO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_CHWO::before,
.data-basic .secondary .page .frame .secondary_icon_CHWO::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .LGAL::before,
.data-basic .secondary .page .frame .LGAL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGAL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_LGAL::before,
.data-basic .secondary .page .frame .secondary_icon_LGAL::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGAL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .LGBL::before,
.data-basic .secondary .page .frame .LGBL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGBL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_LGBL::before,
.data-basic .secondary .page .frame .secondary_icon_LGBL::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGBL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .LGCB::before,
.data-basic .secondary .page .frame .LGCB::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGCB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_LGCB::before,
.data-basic .secondary .page .frame .secondary_icon_LGCB::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGCB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .LGEC::before,
.data-basic .secondary .page .frame .LGEC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_LGEC::before,
.data-basic .secondary .page .frame .secondary_icon_LGEC::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .LGIW::before,
.data-basic .secondary .page .frame .LGIW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGIW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_LGIW::before,
.data-basic .secondary .page .frame .secondary_icon_LGIW::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGIW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .LGNL::before,
.data-basic .secondary .page .frame .LGNL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGNL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_LGNL::before,
.data-basic .secondary .page .frame .secondary_icon_LGNL::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGNL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .LGRC::before,
.data-basic .secondary .page .frame .LGRC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGRC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_LGRC::before,
.data-basic .secondary .page .frame .secondary_icon_LGRC::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGRC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .LGWB::before,
.data-basic .secondary .page .frame .LGWB::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_LGWB::before,
.data-basic .secondary .page .frame .secondary_icon_LGWB::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .LGWE::before,
.data-basic .secondary .page .frame .LGWE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_LGWE::before,
.data-basic .secondary .page .frame .secondary_icon_LGWE::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .HAR::before,
.data-basic .secondary .page .frame .HAR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HAR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_HAR::before,
.data-basic .secondary .page .frame .secondary_icon_HAR::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HAR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .WE::before,
.data-basic .secondary .page .frame .WE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/WE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_WE::before,
.data-basic .secondary .page .frame .secondary_icon_WE::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/WE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .AoI::before,
.data-basic .secondary .page .frame .AoI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AoI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_AoI::before,
.data-basic .secondary .page .frame .secondary_icon_AoI::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AoI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .GSC::before,
.data-basic .secondary .page .frame .GSC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GSC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_GSC::before,
.data-basic .secondary .page .frame .secondary_icon_GSC::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GSC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .LoV::before,
.data-basic .secondary .page .frame .LoV::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LoV.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .secondary_icon_LoV::before,
.data-basic .secondary .page .frame .secondary_icon_LoV::before {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  height: 23px;
  opacity: 0.75;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LoV.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .AM::before,
.data-basic .secondary .page .frame .AM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 55%;
  width: 100%;
  transform: translateY(-75px);
  height: 85px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .basic::before,
.data-basic .secondary .page .frame .basic::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .secondary .page .frame .footer,
.data-basic .secondary .page .frame .footer {
  border-top: 2px solid #000;
  position: absolute;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  bottom: 6px;
  padding-left: 15px;
  padding-right: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
  background: #e2e2dd;
  z-index: 10;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 4px;
  width: calc(100% - 30px);
  left: 0;
}
.data-40k .secondary .page .frame .header,
.data-basic .secondary .page .frame .header {
  display: block;
  height: auto;
  margin: 0 8px;
}
.data-40k .secondary .page .frame .type,
.data-basic .secondary .page .frame .type {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 2px solid #000;
  text-transform: uppercase;
  text-align: center;
  margin-top: 8px;
}
.data-40k .secondary .page .frame .name,
.data-basic .secondary .page .frame .name {
  font-family: "Heebo", sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin-top: 4px;
}
.data-40k .secondary .page .frame .description,
.data-basic .secondary .page .frame .description {
  margin: 8px 8px;
  margin-top: 4px;
  padding: 0 4px;
  vertical-align: middle;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  text-align: justify;
  letter-spacing: 0px;
  line-height: 14px;
}
.data-40k .secondary .page .frame .description table,
.data-basic .secondary .page .frame .description table {
  width: 100%;
}
.data-40k .secondary .page .frame .description table th,
.data-basic .secondary .page .frame .description table th {
  border: 1px solid #777273;
  padding: 4px;
  background-color: #b6beba;
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.data-40k .secondary .page .frame .description table td,
.data-basic .secondary .page .frame .description table td {
  border: 1px solid #777273;
  padding: 4px;
}
.data-40k .secondary .page .frame .description p,
.data-basic .secondary .page .frame .description p {
  margin-bottom: 4px;
}
.data-40k .secondary .page .frame .description ul,
.data-basic .secondary .page .frame .description ul {
  padding-left: 16px;
}
.data-40k .secondary .page .frame .description ul li,
.data-basic .secondary .page .frame .description ul li {
  padding-left: -8px;
}
.data-40k .secondary .page .frame .description ul p,
.data-basic .secondary .page .frame .description ul p {
  margin-bottom: 2px;
}
.data-40k .power .page,
.data-basic .power .page {
  width: inherit;
  display: grid;
  position: relative;
  padding: 8px;
  background-color: #d0d0d0;
}
.data-40k .power .page.playingcard,
.data-basic .power .page.playingcard {
  height: 8.89cm;
  width: 5.71cm;
}
.data-40k .power .page.card,
.data-basic .power .page.card {
  height: 10.8cm;
  width: 7.77cm;
}
.data-40k .power .page.poker,
.data-basic .power .page.poker {
  height: 8.89cm;
  width: 6.35cm;
}
.data-40k .power .page.sheet,
.data-basic .power .page.sheet {
  height: 209.5mm;
  width: 148mm;
}
.data-40k .power .page.a4,
.data-basic .power .page.a4 {
  height: 297mm;
  width: 210mm;
}
.data-40k .power .page.letter,
.data-basic .power .page.letter {
  height: 279mm;
  width: 216mm;
}
.data-40k .power .page.letter-half,
.data-basic .power .page.letter-half {
  height: 216mm;
  width: 139mm;
}
.data-40k .power .page.secondary,
.data-basic .power .page.secondary {
  height: 13.76cm;
  width: 7.77cm;
}
.data-40k .power .page .frame,
.data-basic .power .page .frame {
  display: block;
  height: 100%;
  width: 100%;
  background: black;
  background-color: #e2e2dd;
  overflow: hidden;
  border-radius: 13.5px;
}
.data-40k .power .page .frame .page,
.data-basic .power .page .frame .page {
  display: grid;
  position: relative;
  padding: 8px;
}
.data-40k .power .page .frame .header,
.data-basic .power .page .frame .header {
  display: block;
  height: auto;
  margin: 0 8px;
}
.data-40k .power .page .frame .type,
.data-basic .power .page .frame .type {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 2px solid #000;
  text-transform: uppercase;
  text-align: center;
  margin-top: 8px;
}
.data-40k .power .page .frame .name,
.data-basic .power .page .frame .name {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin-top: 8px;
}
.data-40k .power .page .frame .description,
.data-basic .power .page .frame .description {
  margin: 8px 8px;
  margin-top: 4px;
  padding: 0 4px;
  vertical-align: middle;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  text-align: justify;
  letter-spacing: 0px;
  line-height: 14px;
}
.data-40k .power .page .frame .description table,
.data-basic .power .page .frame .description table {
  width: 100%;
}
.data-40k .power .page .frame .description table th,
.data-basic .power .page .frame .description table th {
  border: 1px solid #777273;
  padding: 4px;
  background-color: #b6beba;
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.data-40k .power .page .frame .description table td,
.data-basic .power .page .frame .description table td {
  border: 1px solid #777273;
  padding: 4px;
}
.data-40k .power .page .frame .description p,
.data-basic .power .page .frame .description p {
  margin-bottom: 4px;
}
.data-40k .power .page .frame .description ul,
.data-basic .power .page .frame .description ul {
  padding-left: 16px;
}
.data-40k .power .page .frame .description ul p,
.data-basic .power .page .frame .description ul p {
  margin-bottom: 2px;
}
.data-40k .power .page .frame .background,
.data-basic .power .page .frame .background {
  width: inherit;
}
.data-40k .power .page .frame .AS::before,
.data-basic .power .page .frame .AS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .AC::before,
.data-basic .power .page .frame .AC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .AdM::before,
.data-basic .power .page .frame .AdM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AdM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .AE::before,
.data-basic .power .page .frame .AE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CA::before,
.data-basic .power .page .frame .CA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .AM::before,
.data-basic .power .page .frame .AM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CD::before,
.data-basic .power .page .frame .CD::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CD.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .QT::before,
.data-basic .power .page .frame .QT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CSM::before,
.data-basic .power .page .frame .CSM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CSM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .DG::before,
.data-basic .power .page .frame .DG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .DRU::before,
.data-basic .power .page .frame .DRU::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DRU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .GC::before,
.data-basic .power .page .frame .GC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .GK::before,
.data-basic .power .page .frame .GK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .HTL::before,
.data-basic .power .page .frame .HTL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HTL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .QI::before,
.data-basic .power .page .frame .QI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .INQ::before,
.data-basic .power .page .frame .INQ::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/INQ.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .NEC::before,
.data-basic .power .page .frame .NEC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/NEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .OA::before,
.data-basic .power .page .frame .OA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/OA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .ORK::before,
.data-basic .power .page .frame .ORK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/ORK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .RaH::before,
.data-basic .power .page .frame .RaH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RaH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .RT::before,
.data-basic .power .page .frame .RT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .SM::before,
.data-basic .power .page .frame .SM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/SM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .TAU::before,
.data-basic .power .page .frame .TAU::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TAU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .TS::before,
.data-basic .power .page .frame .TS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .TL::before,
.data-basic .power .page .frame .TL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .TYR::before,
.data-basic .power .page .frame .TYR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TYR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .UN::before,
.data-basic .power .page .frame .UN::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/UN.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHAC::before,
.data-basic .power .page .frame .CHAC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHAC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHBT::before,
.data-basic .power .page .frame .CHBT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHBA::before,
.data-basic .power .page .frame .CHBA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHBR::before,
.data-basic .power .page .frame .CHBR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHCA::before,
.data-basic .power .page .frame .CHCA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHCR::before,
.data-basic .power .page .frame .CHCR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHCF::before,
.data-basic .power .page .frame .CHCF::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHDA::before,
.data-basic .power .page .frame .CHDA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHDW::before,
.data-basic .power .page .frame .CHDW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHEM::before,
.data-basic .power .page .frame .CHEM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHEX::before,
.data-basic .power .page .frame .CHEX::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEX.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHES::before,
.data-basic .power .page .frame .CHES::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHES.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHFA::before,
.data-basic .power .page .frame .CHFA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHFH::before,
.data-basic .power .page .frame .CHFH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHFT::before,
.data-basic .power .page .frame .CHFT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHHG::before,
.data-basic .power .page .frame .CHHG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHHG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHIF::before,
.data-basic .power .page .frame .CHIF::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHIH::before,
.data-basic .power .page .frame .CHIH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHLA::before,
.data-basic .power .page .frame .CHLA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHLA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHMW::before,
.data-basic .power .page .frame .CHMW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHME::before,
.data-basic .power .page .frame .CHME::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHME.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHMI::before,
.data-basic .power .page .frame .CHMI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHC0::before,
.data-basic .power .page .frame .CHC0::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHC0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHNO::before,
.data-basic .power .page .frame .CHNO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHNO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHRA::before,
.data-basic .power .page .frame .CHRA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHRG::before,
.data-basic .power .page .frame .CHRG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHRS::before,
.data-basic .power .page .frame .CHRS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHSA::before,
.data-basic .power .page .frame .CHSA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHST::before,
.data-basic .power .page .frame .CHST::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHST.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHSO::before,
.data-basic .power .page .frame .CHSO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHSW::before,
.data-basic .power .page .frame .CHSW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHSP::before,
.data-basic .power .page .frame .CHSP::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSP.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHTC::before,
.data-basic .power .page .frame .CHTC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHTK::before,
.data-basic .power .page .frame .CHTK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHUL::before,
.data-basic .power .page .frame .CHUL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHUL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHWS::before,
.data-basic .power .page .frame .CHWS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .CHWO::before,
.data-basic .power .page .frame .CHWO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .LGAL::before,
.data-basic .power .page .frame .LGAL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGAL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .LGBL::before,
.data-basic .power .page .frame .LGBL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGBL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .LGCB::before,
.data-basic .power .page .frame .LGCB::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGCB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .LGEC::before,
.data-basic .power .page .frame .LGEC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .LGIW::before,
.data-basic .power .page .frame .LGIW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGIW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .LGNL::before,
.data-basic .power .page .frame .LGNL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGNL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .LGRC::before,
.data-basic .power .page .frame .LGRC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGRC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .LGWB::before,
.data-basic .power .page .frame .LGWB::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .LGWE::before,
.data-basic .power .page .frame .LGWE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .HAR::before,
.data-basic .power .page .frame .HAR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HAR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .WE::before,
.data-basic .power .page .frame .WE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/WE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .AoI::before,
.data-basic .power .page .frame .AoI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AoI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .GSC::before,
.data-basic .power .page .frame .GSC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GSC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .LoV::before,
.data-basic .power .page .frame .LoV::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LoV.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .AM::before,
.data-basic .power .page .frame .AM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 55%;
  width: 100%;
  transform: translateY(-75px);
  height: 85px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .basic::before,
.data-basic .power .page .frame .basic::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-40k .power .page .frame .footer,
.data-basic .power .page .frame .footer {
  border-top: 2px solid #000;
  position: absolute;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  bottom: 8px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
  background: #e2e2dd;
  z-index: 10;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 4px;
  width: calc(100% - 30px);
  left: 0;
}
.viewer-drawer .ant-drawer-title {
  color: white;
}
.viewer-drawer .ant-drawer-close {
  color: white;
}
.viewer-drawer .ant-drawer-body {
  padding: 0px;
}
.viewer-drawer .ant-list {
  border: 0px;
}
.viewer-drawer .ant-drawer-content-wrapper {
  width: 100% !important;
}
.mobile-list-overview-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 100vw;
  border-bottom: 2px solid #f0f2f5;
  flex-direction: column;
}
.mobile-list-overview-item:active {
  background-color: #00000011;
}
.keyword-popover {
  width: 450px;
}
.keyword-popover .ant-popover-inner-content {
  background-color: rgba(0, 0, 0, 0.75) !important;
  color: white;
}
.points_table-container .ant-popover-inner-content {
  padding: 0px 0px;
  background-color: var(--title-background-colour);
  border: 1px solid white;
}
.points_table-container .point-tabel {
  padding: 8px;
  font-family: "Heebo", sans-serif;
  border-collapse: collapse;
  font-size: 0.9em;
  width: 150px;
  border: 0px solid #001529;
}
.points_table-container .point-tabel thead {
  text-align: center;
  background-color: #001529;
  text-transform: uppercase;
  color: white;
  font-size: 0.9em;
  font-weight: 800;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
}
.points_table-container .point-tabel tbody {
  background-color: var(--text-background-colour);
  color: var(--text-text-colour);
  font-size: 1.1em;
  font-weight: 800;
  text-align: center;
}
.points_table-container .point-tabel tbody tr td {
  white-space: nowrap;
}
@media only screen and (max-width: 600px) {
  .multi-line {
    position: relative;
  }
  .multi-line .value:first-child {
    padding-left: 18px;
  }
  .ant-tabs-nav-wrap {
    background-color: white;
  }
  .ant-tabs-nav-wrap .ant-tabs-nav-list {
    width: 90%;
  }
  .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
    border-color: var(--header-colour) !important;
    background-color: var(--header-colour) !important;
  }
  .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--banner-colour);
  }
  .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    display: flex;
    justify-content: center;
    width: 45%;
  }
  .shared-stratagem {
    height: 458px;
    width: 262px;
    transform: scale3d(var(--card-scaling-factor), var(--card-scaling-factor), var(--card-scaling-factor));
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--stratagem-colour);
  }
  .shared-stratagem.AS::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AS.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.AC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.AdM::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AdM.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.AE::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AE.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.AM::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CD::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CD.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.QT::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QT.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CSM::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CSM.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.DG::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DG.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.DRU::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DRU.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.GC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.GK::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GK.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.HTL::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HTL.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.QI::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QI.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.INQ::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/INQ.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.NEC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/NEC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.OA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/OA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.ORK::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/ORK.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.RaH::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RaH.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.RT::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RT.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.SM::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/SM.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.TAU::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TAU.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.TS::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TS.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.TL::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TL.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.TYR::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TYR.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.UN::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/UN.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHAC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHAC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHBT::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBT.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHBA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHBR::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBR.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHCA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHCR::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCR.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHCF::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCF.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHDA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHDW::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDW.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHEM::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEM.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHEX::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEX.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHES::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHES.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHFA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHFH::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFH.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHFT::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFT.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHHG::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHHG.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHIF::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIF.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHIH::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIH.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHLA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHLA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHMW::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMW.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHME::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHME.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHMI::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMI.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHC0::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHC0.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHNO::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHNO.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHRA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHRG::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRG.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHRS::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRS.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHSA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHST::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHST.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHSO::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSO.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHSW::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSW.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHSP::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSP.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHTC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHTK::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTK.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHUL::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHUL.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHWS::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWS.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.CHWO::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWO.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.LGAL::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGAL.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.LGBL::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGBL.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.LGCB::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGCB.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.LGEC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGEC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.LGIW::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGIW.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.LGNL::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGNL.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.LGRC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGRC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.LGWB::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWB.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.LGWE::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWE.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.HAR::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HAR.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.WE::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/WE.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.AoI::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AoI.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.GSC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GSC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.LoV::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LoV.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.AM::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 55%;
    width: 100%;
    transform: translateY(-75px);
    height: 85px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem.basic::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .shared-stratagem .border {
    background-color: white;
    border-radius: 8px;
    height: 456px;
    width: 260px;
  }
  .shared-stratagem .border .background-side-bar {
    position: absolute;
    top: 22.67px;
    left: 22.67px;
    height: 377.95px;
    width: 15.11px;
    background-color: var(--stratagem-colour);
  }
  .shared-stratagem .border .background-header-bar {
    position: absolute;
    top: 32.12px;
    left: 41.57px;
    height: 3.77px;
    width: 200.31px;
    background-color: var(--stratagem-colour);
  }
  .shared-stratagem .border .header {
    padding-left: 4px;
    position: absolute;
    top: 9.44px;
    left: 52.91px;
    color: var(--stratagem-colour);
    width: 185.19px;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  .shared-stratagem .border .type {
    padding-left: 4px;
    position: absolute;
    top: 35.9px;
    left: 52.91px;
    width: 188.97;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px dotted #aaadb0;
  }
  .shared-stratagem .border .content {
    position: absolute;
    top: 56.69px;
    left: 52.91px;
    width: 188.97px;
    height: auto;
    padding-left: 4px;
  }
  .shared-stratagem .border .content .section {
    padding-top: 4px;
    line-height: 1rem;
  }
  .shared-stratagem .border .content .section .title {
    text-transform: uppercase;
    color: var(--stratagem-colour);
    font-size: 0.7rem;
    font-weight: 600;
    padding-right: 4px;
  }
  .shared-stratagem .border .content .section .text {
    color: black;
    font-size: 0.7rem;
    font-weight: 400;
  }
  .shared-stratagem .border .containers {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 35.9px;
    height: auto;
    top: 16.06px;
    left: 12.28px;
  }
  .shared-stratagem .border .containers .cp-container {
    height: 35.9px;
    width: 35.9px;
    border: 3.4px solid var(--stratagem-colour);
    background-color: var(--rows-colour);
    rotate: 45deg;
    overflow: hidden;
    line-height: 28.34px;
  }
  .shared-stratagem .border .containers .cp-container .value {
    rotate: -45deg;
    text-align: center;
    font-weight: 600;
    color: var(--stratagem-colour);
    font-size: 1.1rem;
  }
  .stratagem {
    position: relative;
  }
  .stratagem .content {
    height: auto;
  }
  .stratagem .content .section {
    padding-top: 4px;
  }
  .stratagem .content .section:nth-of-type(1) {
    padding-top: 0px;
  }
  .stratagem .content .section .title {
    text-transform: uppercase;
    color: var(--banner-colour);
    font-weight: 600;
    padding-right: 4px;
  }
  .stratagem .content .section .text {
    color: black;
    font-size: 0.9rem;
    line-height: 0.9rem;
  }
  .stratagem .type-container {
    height: 35.9px;
    width: 35.9px;
    border: 3.4px solid var(--header-colour);
    background-color: var(--rows-colour);
    position: absolute;
    top: 16.06px;
    left: 12.28px;
    rotate: 45deg;
    overflow: hidden;
  }
  .stratagem .cp-container {
    height: 35.9px;
    width: 35.9px;
    border: 3.4px solid var(--header-colour);
    background-color: var(--rows-colour);
    position: absolute;
    top: 83.14px;
    left: 12.28px;
    rotate: 45deg;
    overflow: hidden;
    line-height: 28.34px;
  }
  .stratagem .cp-container .value {
    rotate: -45deg;
    text-align: center;
    font-weight: 600;
    color: var(--header-colour);
    font-size: 1.1rem;
  }
  html {
    font-size: 15px;
  }
  .welcome-background {
    border-radius: 4px;
    width: 100%;
    height: 100vh;
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    transform: initial;
    z-index: 10000;
  }
  .welcome-background .whatsnew-container {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    transform: initial;
  }
  .welcome-background .whatsnew-container .whatsnew-content {
    overflow-y: auto;
    height: calc(100vh - 175px);
  }
  .list-item .AS::before {
    right: 80px;
  }
  .list-item .AC::before {
    right: 80px;
  }
  .list-item .AdM::before {
    right: 80px;
  }
  .list-item .AE::before {
    right: 80px;
  }
  .list-item .CA::before {
    right: 80px;
  }
  .list-item .AM::before {
    right: 80px;
  }
  .list-item .CD::before {
    right: 80px;
  }
  .list-item .QT::before {
    right: 80px;
  }
  .list-item .CSM::before {
    right: 80px;
  }
  .list-item .DG::before {
    right: 80px;
  }
  .list-item .DRU::before {
    right: 80px;
  }
  .list-item .GC::before {
    right: 80px;
  }
  .list-item .GK::before {
    right: 80px;
  }
  .list-item .HTL::before {
    right: 80px;
  }
  .list-item .QI::before {
    right: 80px;
  }
  .list-item .INQ::before {
    right: 80px;
  }
  .list-item .NEC::before {
    right: 80px;
  }
  .list-item .OA::before {
    right: 80px;
  }
  .list-item .ORK::before {
    right: 80px;
  }
  .list-item .RaH::before {
    right: 80px;
  }
  .list-item .RT::before {
    right: 80px;
  }
  .list-item .SM::before {
    right: 80px;
  }
  .list-item .TAU::before {
    right: 80px;
  }
  .list-item .TS::before {
    right: 80px;
  }
  .list-item .TL::before {
    right: 80px;
  }
  .list-item .TYR::before {
    right: 80px;
  }
  .list-item .UN::before {
    right: 80px;
  }
  .list-item .CHAC::before {
    right: 80px;
  }
  .list-item .CHBT::before {
    right: 80px;
  }
  .list-item .CHBA::before {
    right: 80px;
  }
  .list-item .CHBR::before {
    right: 80px;
  }
  .list-item .CHCA::before {
    right: 80px;
  }
  .list-item .CHCR::before {
    right: 80px;
  }
  .list-item .CHCF::before {
    right: 80px;
  }
  .list-item .CHDA::before {
    right: 80px;
  }
  .list-item .CHDW::before {
    right: 80px;
  }
  .list-item .CHEM::before {
    right: 80px;
  }
  .list-item .CHEX::before {
    right: 80px;
  }
  .list-item .CHES::before {
    right: 80px;
  }
  .list-item .CHFA::before {
    right: 80px;
  }
  .list-item .CHFH::before {
    right: 80px;
  }
  .list-item .CHFT::before {
    right: 80px;
  }
  .list-item .CHHG::before {
    right: 80px;
  }
  .list-item .CHIF::before {
    right: 80px;
  }
  .list-item .CHIH::before {
    right: 80px;
  }
  .list-item .CHLA::before {
    right: 80px;
  }
  .list-item .CHMW::before {
    right: 80px;
  }
  .list-item .CHME::before {
    right: 80px;
  }
  .list-item .CHMI::before {
    right: 80px;
  }
  .list-item .CHC0::before {
    right: 80px;
  }
  .list-item .CHNO::before {
    right: 80px;
  }
  .list-item .CHRA::before {
    right: 80px;
  }
  .list-item .CHRG::before {
    right: 80px;
  }
  .list-item .CHRS::before {
    right: 80px;
  }
  .list-item .CHSA::before {
    right: 80px;
  }
  .list-item .CHST::before {
    right: 80px;
  }
  .list-item .CHSO::before {
    right: 80px;
  }
  .list-item .CHSW::before {
    right: 80px;
  }
  .list-item .CHSP::before {
    right: 80px;
  }
  .list-item .CHTC::before {
    right: 80px;
  }
  .list-item .CHTK::before {
    right: 80px;
  }
  .list-item .CHUL::before {
    right: 80px;
  }
  .list-item .CHWS::before {
    right: 80px;
  }
  .list-item .CHWO::before {
    right: 80px;
  }
  .list-item .LGAL::before {
    right: 80px;
  }
  .list-item .LGBL::before {
    right: 80px;
  }
  .list-item .LGCB::before {
    right: 80px;
  }
  .list-item .LGEC::before {
    right: 80px;
  }
  .list-item .LGIW::before {
    right: 80px;
  }
  .list-item .LGNL::before {
    right: 80px;
  }
  .list-item .LGRC::before {
    right: 80px;
  }
  .list-item .LGWB::before {
    right: 80px;
  }
  .list-item .LGWE::before {
    right: 80px;
  }
  .list-item .HAR::before {
    right: 80px;
  }
  .list-item .WE::before {
    right: 80px;
  }
  .list-item .AoI::before {
    right: 80px;
  }
  .list-item .GSC::before {
    right: 80px;
  }
  .list-item .LoV::before {
    right: 80px;
  }
  .list-item.legends::before {
    position: absolute;
    right: 86px;
    content: "L";
    font-size: 1.3rem;
    font-weight: 600;
    font-family: "EB Garamond", serif;
  }
}
@media only screen and (min-width: 600px) {
  html {
    font-size: 16px;
  }
  .list-item.legends::before {
    position: absolute;
    right: 16px;
    content: "L";
    font-size: 1.3rem;
    font-weight: 600;
    font-family: "EB Garamond", serif;
  }
}
.data-40k-10e {
  page-break-inside: avoid;
  --card-scaling: 100;
  --card-scaling-factor: 1;
  --header-colour: #456664;
  --header-text-colour: white;
  --stat-text-colour: #456664;
  --stat-title-colour: white;
  --banner-colour: #103344;
  --text-background-colour: #dfe0e2;
  --rows-colour: #d8d8da;
  --alt-rows-colour: #dee3e0;
  --keywords-background-colour: #d8d8da;
  --weapon-keyword-colour: #456664;
  --background-colour: black;
  --faction-text-colour: white;
  --title-text-colour: white;
  --green-stratagem-colour: #2c594c;
  --blue-stratagem-colour: #234461;
  --red-stratagem-colour: #a2151a;
  --title-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --title-letter-spacing: default;
  --title-font-weight: 600;
}
.data-40k-10e .stratagem.either,
.data-40k-10e .shared-stratagem.either {
  --stratagem-colour: var(--green-stratagem-colour);
}
.data-40k-10e .stratagem.own,
.data-40k-10e .shared-stratagem.own {
  --stratagem-colour: var(--blue-stratagem-colour);
}
.data-40k-10e .stratagem.other,
.data-40k-10e .shared-stratagem.other {
  --stratagem-colour: var(--red-stratagem-colour);
}
.data-40k-10e .stratagem .containers .type-container,
.data-40k-10e .shared-stratagem .containers .type-container {
  height: 35.9px;
  width: 35.9px;
  border: 3.5px solid var(--stratagem-colour);
  background-color: var(--rows-colour);
  rotate: 45deg;
  overflow: hidden;
}
.data-40k-10e .stratagem .containers .type-container .any,
.data-40k-10e .shared-stratagem .containers .type-container .any {
  mask: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/10th/any.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background-color: var(--stratagem-colour);
  display: block;
  width: 95%;
  height: 95%;
  margin-left: 1px;
  margin-top: -1px;
  rotate: -45deg;
}
.data-40k-10e .stratagem .containers .type-container .charge,
.data-40k-10e .shared-stratagem .containers .type-container .charge {
  mask: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/10th/charge.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background-color: var(--stratagem-colour);
  display: block;
  width: 100%;
  height: 100%;
  margin-top: -2px;
  margin-left: -2px;
  rotate: -45deg;
}
.data-40k-10e .stratagem .containers .type-container .fight,
.data-40k-10e .shared-stratagem .containers .type-container .fight {
  mask: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/10th/combat.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background-color: var(--stratagem-colour);
  display: block;
  width: 95%;
  height: 95%;
  rotate: -45deg;
}
.data-40k-10e .stratagem .containers .type-container .command,
.data-40k-10e .shared-stratagem .containers .type-container .command {
  mask: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/10th/command.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background-color: var(--stratagem-colour);
  display: block;
  width: 100%;
  height: 100%;
  rotate: -45deg;
}
.data-40k-10e .stratagem .containers .type-container .movement,
.data-40k-10e .shared-stratagem .containers .type-container .movement {
  mask: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/10th/movement.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background-color: var(--stratagem-colour);
  display: block;
  width: 95%;
  height: 95%;
  rotate: -45deg;
}
.data-40k-10e .stratagem .containers .type-container .shooting,
.data-40k-10e .shared-stratagem .containers .type-container .shooting {
  mask: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/10th/shooting.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background-color: var(--stratagem-colour);
  display: block;
  width: 100%;
  height: 100%;
  rotate: -45deg;
}
.data-40k-10e .mobile .stratagem .border {
  border-left: 5px solid var(--stratagem-colour);
  padding-left: 15px;
  margin-left: 4px;
}
.data-40k-10e .mobile .stratagem .header,
.data-40k-10e .mobile .stratagem .cp-container {
  display: none;
}
.data-40k-10e .mobile .stratagem .containers .type-container {
  height: 25px;
  width: 25px;
  border-width: 2px;
  top: 0.5px;
  left: -6.4px;
}
.data-40k-10e .mobile .stratagem .type {
  margin-left: 5px;
}
@media print, (min-width: 600px) {
  .data-40k-10e .stratagem {
    height: 458px;
    width: 262px;
    transform: scale3d(var(--card-scaling-factor), var(--card-scaling-factor), var(--card-scaling-factor));
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--stratagem-colour);
  }
  .data-40k-10e .stratagem.AS::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AS.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.AC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.AdM::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AdM.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.AE::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AE.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.AM::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CD::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CD.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.QT::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QT.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CSM::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CSM.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.DG::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DG.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.DRU::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DRU.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.GC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.GK::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GK.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.HTL::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HTL.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.QI::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QI.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.INQ::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/INQ.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.NEC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/NEC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.OA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/OA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.ORK::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/ORK.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.RaH::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RaH.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.RT::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RT.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.SM::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/SM.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.TAU::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TAU.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.TS::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TS.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.TL::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TL.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.TYR::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TYR.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.UN::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/UN.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHAC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHAC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHBT::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBT.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHBA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHBR::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBR.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHCA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHCR::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCR.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHCF::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCF.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHDA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHDW::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDW.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHEM::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEM.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHEX::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEX.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHES::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHES.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHFA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHFH::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFH.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHFT::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFT.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHHG::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHHG.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHIF::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIF.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHIH::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIH.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHLA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHLA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHMW::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMW.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHME::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHME.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHMI::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMI.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHC0::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHC0.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHNO::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHNO.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHRA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHRG::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRG.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHRS::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRS.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHSA::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSA.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHST::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHST.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHSO::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSO.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHSW::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSW.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHSP::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSP.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHTC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHTK::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTK.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHUL::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHUL.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHWS::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWS.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.CHWO::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWO.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.LGAL::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGAL.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.LGBL::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGBL.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.LGCB::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGCB.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.LGEC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGEC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.LGIW::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGIW.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.LGNL::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGNL.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.LGRC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGRC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.LGWB::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWB.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.LGWE::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWE.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.HAR::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HAR.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.WE::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/WE.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.AoI::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AoI.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.GSC::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GSC.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.LoV::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LoV.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.AM::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 55%;
    width: 100%;
    transform: translateY(-75px);
    height: 85px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem.basic::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-75px);
    height: 150px;
    opacity: 0.1;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .data-40k-10e .stratagem .border {
    background-color: white;
    border-radius: 8px;
    height: 456px;
    width: 260px;
  }
  .data-40k-10e .stratagem .border .background-side-bar {
    position: absolute;
    top: 22.67px;
    left: 22.67px;
    height: 377.95px;
    width: 15.11px;
    background-color: var(--stratagem-colour);
  }
  .data-40k-10e .stratagem .border .background-header-bar {
    position: absolute;
    top: 32.12px;
    left: 41.57px;
    height: 3.77px;
    width: 200.31px;
    background-color: var(--stratagem-colour);
  }
  .data-40k-10e .stratagem .border .header {
    padding-left: 4px;
    position: absolute;
    top: 9.44px;
    left: 52.91px;
    color: var(--stratagem-colour);
    width: 185.19px;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  .data-40k-10e .stratagem .border .type {
    padding-left: 4px;
    position: absolute;
    top: 35.9px;
    left: 52.91px;
    width: 188.97;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px dotted #aaadb0;
  }
  .data-40k-10e .stratagem .border .content {
    position: absolute;
    top: 56.69px;
    left: 52.91px;
    width: 188.97px;
    height: auto;
    padding-left: 4px;
  }
  .data-40k-10e .stratagem .border .content .section {
    padding-top: 4px;
    line-height: 1rem;
  }
  .data-40k-10e .stratagem .border .content .section .title {
    text-transform: uppercase;
    color: var(--stratagem-colour);
    font-size: 0.7rem;
    font-weight: 600;
    padding-right: 4px;
  }
  .data-40k-10e .stratagem .border .content .section .text {
    color: black;
    font-size: 0.7rem;
    font-weight: 400;
  }
  .data-40k-10e .stratagem .border .containers {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 35.9px;
    height: auto;
    top: 16.06px;
    left: 12.28px;
  }
  .data-40k-10e .stratagem .border .containers .cp-container {
    height: 35.9px;
    width: 35.9px;
    border: 3.4px solid var(--stratagem-colour);
    background-color: var(--rows-colour);
    rotate: 45deg;
    overflow: hidden;
    line-height: 28.34px;
  }
  .data-40k-10e .stratagem .border .containers .cp-container .value {
    rotate: -45deg;
    text-align: center;
    font-weight: 600;
    color: var(--stratagem-colour);
    font-size: 1.1rem;
  }
}
.data-40k-10e .card-editor .stats_container {
  padding-left: 8px;
  display: flex;
  align-items: flex-end;
  gap: 16px;
}
.data-40k-10e .card-editor .stats_container .stat {
  z-index: 2;
  position: relative;
  flex-direction: row;
  justify-content: center;
}
.data-40k-10e .card-editor .stats_container .stat .caption {
  text-align: center;
  font-size: 0.9rem;
  color: black;
  font-weight: 600;
  width: 40px;
  height: 20px;
}
.data-40k-10e .card-editor .stats_container .stat .value_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--header-colour);
  height: 40px;
  width: 40px;
  z-index: 1;
  clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%, 10% 0);
}
.data-40k-10e .card-editor .stats_container .stat .value_container .value {
  text-align: center;
  height: 36px;
  width: 36px;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--header-colour);
  z-index: 2;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%, 10% 0);
}
.data-40k-10e .card-editor .stats_container .stat .value_container .value input {
  border: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--header-colour);
  text-align: center;
  width: 100%;
  padding: 0;
}
.data-40k-10e .card-editor .weapons_header {
  background-color: #fafafa;
  color: black;
  font-size: 0.9rem;
  font-weight: 600;
}
.data-40k-10e .card-editor .keywords_header {
  background-color: #fafafa;
  color: black;
  font-size: 0.9rem;
  font-weight: 600;
  padding-left: 8px;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .card-editor .keywords_container {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .card-editor .keywords_container .keyword_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px;
  margin: 2px;
  border-bottom: 1px dotted #aaadb0;
}
.data-40k-10e .card-editor .weapons_container,
.data-40k-10e .card-editor .weapons_header {
  padding-left: 8px;
  display: flex;
  align-items: flex-end;
  gap: 16px;
}
.data-40k-10e .card-editor .weapons_container .tag,
.data-40k-10e .card-editor .weapons_header .tag {
  border: 1px solid var(--header-colour);
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.data-40k-10e .card-editor .weapons_container .weapon,
.data-40k-10e .card-editor .weapons_header .weapon {
  z-index: 2;
  position: relative;
  flex-direction: row;
  justify-content: center;
}
.data-40k-10e .card-editor .weapons_container .weapon .caption,
.data-40k-10e .card-editor .weapons_header .weapon .caption {
  text-align: center;
  font-size: 0.9rem;
  color: black;
  font-weight: 600;
  width: 36px;
  height: 20px;
}
.data-40k-10e .card-editor .weapons_container .weapon .value,
.data-40k-10e .card-editor .weapons_header .weapon .value {
  text-align: center;
  width: 36px;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--header-colour);
  z-index: 2;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
}
.data-40k-10e .card-editor .weapons_container .weapon .value input,
.data-40k-10e .card-editor .weapons_header .weapon .value input {
  font-size: 1rem;
  font-weight: 600;
  color: var(--header-colour);
  text-align: center;
  width: 100%;
  padding: 0;
}
.data-40k-10e .card-editor .weapons_container .weapon .double,
.data-40k-10e .card-editor .weapons_header .weapon .double {
  width: 72px;
}
.data-40k-10e .unit {
  height: calc(714px);
  width: calc(1077px);
  transform: scale(var(--card-scaling-factor));
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.data-40k-10e .unit .header {
  height: 196px;
  width: 1077px;
  background-color: var(--background-colour);
  position: relative;
  display: grid;
  row-gap: 2px;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: " c1  c1 " " c2  c2 " " c3  c5 " " c4  c6";
}
.data-40k-10e .unit .header .stats_container:nth-child(2) {
  grid-area: c2;
}
.data-40k-10e .unit .header .stats_container:nth-child(3) {
  grid-area: c3;
}
.data-40k-10e .unit .header .stats_container:nth-child(4) {
  grid-area: c4;
}
.data-40k-10e .unit .header .stats_container:nth-child(5) {
  grid-area: c5;
}
.data-40k-10e .unit .header .stats_container:nth-child(6) {
  grid-area: c6;
}
.data-40k-10e .unit .header .invul_container {
  z-index: 2;
  position: relative;
  padding-left: 120px;
  display: flex;
  flex-direction: column;
}
.data-40k-10e .unit .header .invul_container .info {
  color: white;
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: normal;
  font-style: italic;
  margin-top: -12px;
  padding-left: 54px;
}
.data-40k-10e .unit .header .invul_container .invul {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.data-40k-10e .unit .header .invul_container .invul::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 22px;
  background-color: var(--banner-colour);
  top: calc(50% - 11px);
}
.data-40k-10e .unit .header .invul_container .invul .title {
  text-transform: uppercase;
  color: var(--header-text-colour);
  font-size: 0.8rem;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 8px;
}
.data-40k-10e .unit .header .invul_container .invul .value_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  z-index: 1;
  mask: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/invul_shield.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background-color: var(--banner-colour);
}
.data-40k-10e .unit .header .invul_container .invul .value_container .value {
  text-align: center;
  height: 38px;
  width: 39px;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--stat-text-colour);
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: white;
  mask: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/invul_shield.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}
.data-40k-10e .unit .header .header_container {
  grid-area: c1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 32px;
  padding-left: 32px;
}
@media only screen and (max-width: 600px) {
  .data-40k-10e .unit .header .header_container .legends {
    position: absolute;
    right: 16px;
    top: 8px;
    font-size: 1rem;
  }
  .data-40k-10e .unit .header .header_container .legends::before {
    content: "Legends";
  }
}
@media only screen and (min-width: 600px) {
  .data-40k-10e .unit .header .header_container .legends {
    position: absolute;
    top: 38px;
    right: 110px;
    z-index: 10;
    font-size: 1.3rem;
    font-weight: 600;
    font-family: "EB Garamond", "serif";
    color: var(--faction-text-colour);
  }
  .data-40k-10e .unit .header .header_container .legends::before {
    content: "Warhammer Legends";
  }
  .data-40k-10e .unit .header .header_container .combatpatrol {
    position: absolute;
    top: 38px;
    right: 110px;
    z-index: 10;
    font-size: 1.3rem;
    font-weight: 600;
    font-family: "EB Garamond", "serif";
    color: var(--faction-text-colour);
  }
  .data-40k-10e .unit .header .header_container .combatpatrol::before {
    content: "Combat Patrol Datasheet";
  }
}
.data-40k-10e .unit .header .header_container .name_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--title-font-family);
}
.data-40k-10e .unit .header .header_container .name_container .name {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 800;
  color: var(--title-text-colour);
  z-index: 3;
  line-height: 2.5rem;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .unit .header .header_container .name_container .subname {
  padding-left: 8px;
  text-transform: uppercase;
  font-style: italic;
  color: var(--title-text-colour);
  font-size: 1.1rem;
  z-index: 2;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .unit .header .header_container .points_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  z-index: 2;
  padding-top: 4px;
  height: auto;
}
.data-40k-10e .unit .header .header_container .points_container .points {
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  color: var(--title-text-colour);
  z-index: 2;
  line-height: 2.2rem;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid var(--title-text-colour);
  border-radius: 4px;
}
.data-40k-10e .unit .header .header_container .points_container .points:hover {
  border: 1px solid var(--title-text-colour);
  background-color: #ffffff44;
  cursor: pointer;
}
.data-40k-10e .unit .header .stats_container {
  padding-left: 32px;
  display: flex;
  align-items: flex-end;
  gap: 16px;
  font-family: var(--title-font-family);
}
.data-40k-10e .unit .header .stats_container:last-child {
  padding-top: 4px;
}
.data-40k-10e .unit .header .stats_container .name {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  color: var(--stat-title-colour);
  z-index: 2;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  white-space: nowrap;
  width: 135px;
}
.data-40k-10e .unit .header .stats_container .stat {
  z-index: 2;
  position: relative;
  flex-direction: row;
  justify-content: center;
}
.data-40k-10e .unit .header .stats_container .stat .caption {
  text-align: center;
  font-size: 0.9rem;
  color: var(--title-text-colour);
  font-weight: 600;
  width: 44px;
  height: 20px;
}
.data-40k-10e .unit .header .stats_container .stat .damageTable {
  position: absolute;
  bottom: -14px;
  height: 24px;
  width: 24px;
  left: 10px;
  border-radius: 32px;
  background-color: var(--header-colour);
}
.data-40k-10e .unit .header .stats_container .stat .damageTable::after {
  content: " ";
  position: absolute;
  filter: invert(87%) sepia(100%) saturate(0%) hue-rotate(303deg) brightness(104%) contrast(101%);
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Toughness.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
  width: 100%;
  scale: 0.75;
}
.data-40k-10e .unit .header .stats_container .stat .value_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--header-colour);
  height: 44px;
  width: 44px;
  z-index: 1;
  clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%, 10% 0);
}
.data-40k-10e .unit .header .stats_container .stat .value_container .value {
  text-align: center;
  height: 40px;
  width: 40px;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--stat-text-colour);
  z-index: 2;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%, 10% 0);
}
.data-40k-10e .unit .header .stats_container .stat .value_container.wide {
  width: 60px;
}
.data-40k-10e .unit .header .stats_container .stat .value_container.wide .value {
  width: 54px;
}
.data-40k-10e .unit .header::after {
  position: absolute;
  content: "";
  top: 32px;
  left: 24px;
  width: calc(1077px - 24px);
  height: 90px;
  background-color: var(--banner-colour);
  clip-path: polygon(0% 0%, 0% 100%, 42% 100%, 52% 50%, 100% 50%, 100% 0%);
}
.data-40k-10e .unit .header.back::after {
  position: absolute;
  content: "";
  top: 32px;
  left: 24px;
  width: calc(1077px - 24px);
  height: 90px;
  background-color: var(--banner-colour);
  clip-path: polygon(0% 0%, 0% 50%, 0% 50%, 52% 50%, 100% 50%, 100% 0%) !important;
}
.data-40k-10e .unit.full {
  height: auto;
  min-height: calc(714px);
  margin-bottom: 64px;
}
.data-40k-10e .unit.full .footer {
  position: initial;
  margin-top: -32px;
  z-index: 200;
}
.data-40k-10e .unit.full .faction {
  z-index: 201;
  position: initial;
  margin-top: -64px;
  margin-left: 339px;
}
.data-40k-10e .unit.full .extra {
  height: initial !important;
  border-bottom: 2px solid var(--header-colour);
}
.data-40k-10e .unit.full .extra > div:last-child {
  padding-bottom: 32px;
}
.data-40k-10e .unit.full .data_container {
  height: auto;
  min-height: 468px;
}
.data-40k-10e .unit.full .data_container .data {
  height: auto;
  min-height: 468px;
  display: grid;
  grid-template-areas: " weapons extra " " multi-data extra";
}
.data-40k-10e .unit.full .data_container .data .weapons {
  grid-area: weapons;
  height: auto;
}
.data-40k-10e .unit.full .data_container .data .multi-data {
  grid-area: multi-data;
}
.data-40k-10e .unit.full .data_container .data .extra {
  grid-area: extra;
}
.data-40k-10e .unit.full .data_container .data .multi-data {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-right: 2px solid var(--header-colour);
  padding-bottom: 48px;
}
.data-40k-10e .unit.full .data_container .data .multi-data .wargear_container {
  padding: 0px;
  height: auto;
  border-right: none;
}
.data-40k-10e .unit.full .data_container .data .multi-data .wargear_container .content {
  grid-template-rows: auto !important;
  grid-template-columns: 1fr !important;
  grid-auto-flow: row !important;
}
.data-40k-10e .unit.full .data_container .data .multi-data .wargear_container .content ul {
  list-style: none;
  padding: 0;
}
.data-40k-10e .unit.full .data_container .data .multi-data .wargear_container .content ul li::before {
  content: "◦";
  /* Insert content that looks like bullets */
  padding-right: 8px;
  list-style-position: inside;
}
.data-40k-10e .unit.full .data_container .data .multi-data .extra {
  padding: 0px;
  height: auto;
  border-right: none;
  border-bottom: none;
}
.data-40k-10e .unit.full .data_container .data .multi-data .extra .heading:nth-of-type(1) {
  margin-top: 0px;
}
.data-40k-10e .unit.full .data_container .data .multi-data .extra .heading {
  margin-top: 16px;
}
.data-40k-10e .unit.full .data_container .data .multi-data .extra .ledBy {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.data-40k-10e .unit.full .data_container .data .multi-data .extra .ledBy .description {
  grid-column: span 2;
}
.data-40k-10e .unit.full .data_container .data .multi-data .extra .composition_container > div {
  padding-left: 16px;
}
.data-40k-10e .unit.full .data_container .data .multi-data .extra .composition_container > div .description {
  padding-left: 0px;
}
.data-40k-10e .unit .data_container {
  position: relative;
  height: 468px;
  width: 1032px;
  background-color: var(--header-colour);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 2% 100%, 0 96%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.data-40k-10e .unit .data_container .data {
  top: 0;
  margin-top: -4px;
  height: 468px;
  width: 1028px;
  background-color: var(--text-background-colour);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 2% 100%, 0 96%);
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.data-40k-10e .unit .data_container .data .weapons {
  height: 500px;
  border-right: 2px solid var(--header-colour);
  padding-top: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
}
.data-40k-10e .unit .data_container .data .weapons .ranged {
  position: relative;
}
.data-40k-10e .unit .data_container .data .weapons .ranged::before {
  content: "";
  position: absolute;
  left: 4px;
  top: 0;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/BallisticSkill.svg");
  background-repeat: none;
  filter: invert(87%) sepia(100%) saturate(0%) hue-rotate(303deg) brightness(104%) contrast(101%);
  width: 24px;
  height: 24px;
}
.data-40k-10e .unit .data_container .data .weapons .ranged .heading {
  background-color: var(--header-colour);
  padding-left: 32px;
  display: grid;
  grid-template-columns: 7fr 2fr repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.data-40k-10e .unit .data_container .data .weapons .ranged .heading .title {
  font-family: var(--title-font-family);
  text-transform: uppercase;
  color: var(--header-text-colour);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
}
.data-40k-10e .unit .data_container .data .weapons .ranged .multi-line {
  position: relative;
}
.data-40k-10e .unit .data_container .data .weapons .ranged .multi-line::after {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  width: 16px;
  height: 8px;
  background-color: var(--header-colour);
  clip-path: polygon(0% 0%, 55% 0%, 100% 50%, 55% 100%, 0% 100%);
  z-index: 1;
}
.data-40k-10e .unit .data_container .data .weapons .ranged .weapon {
  padding-left: 32px;
  border-bottom: 1px dotted #636567;
}
.data-40k-10e .unit .data_container .data .weapons .ranged .weapon:nth-of-type(even) {
  background: var(--rows-colour);
}
.data-40k-10e .unit .data_container .data .weapons .ranged .weapon:nth-of-type(odd) {
  background: var(--alt-rows-colour);
}
.data-40k-10e .unit .data_container .data .weapons .ranged .weapon .line {
  display: grid;
  grid-template-columns: 7fr 2fr repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.data-40k-10e .unit .data_container .data .weapons .ranged .weapon .line .value {
  color: black;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: normal;
  z-index: 2;
}
.data-40k-10e .unit .data_container .data .weapons .ranged .weapon .line .value .name {
  white-space: pre-line;
}
.data-40k-10e .unit .data_container .data .weapons .ranged .weapon .line .value .keyword {
  white-space: nowrap;
}
.data-40k-10e .unit .data_container .data .weapons .melee {
  position: relative;
}
.data-40k-10e .unit .data_container .data .weapons .melee::before {
  content: "";
  position: absolute;
  left: 4px;
  top: 0;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Melee.svg");
  background-repeat: none;
  filter: invert(87%) sepia(100%) saturate(0%) hue-rotate(303deg) brightness(104%) contrast(101%);
  width: 20px;
  height: 20px;
  margin-top: 2px;
}
.data-40k-10e .unit .data_container .data .weapons .melee .multi-line {
  position: relative;
}
.data-40k-10e .unit .data_container .data .weapons .melee .multi-line::after {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  width: 16px;
  height: 8px;
  background-color: var(--header-colour);
  clip-path: polygon(0% 0%, 55% 0%, 100% 50%, 55% 100%, 0% 100%);
  z-index: 1;
}
.data-40k-10e .unit .data_container .data .weapons .melee .heading {
  background-color: var(--header-colour);
  padding-left: 32px;
  display: grid;
  grid-template-columns: 7fr 2fr repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.data-40k-10e .unit .data_container .data .weapons .melee .heading .title {
  font-family: var(--title-font-family);
  text-transform: uppercase;
  color: var(--header-text-colour);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
}
.data-40k-10e .unit .data_container .data .weapons .melee .weapon {
  padding-left: 32px;
  border-bottom: 1px dotted #636567;
}
.data-40k-10e .unit .data_container .data .weapons .melee .weapon:nth-of-type(even) {
  background: var(--rows-colour);
}
.data-40k-10e .unit .data_container .data .weapons .melee .weapon:nth-of-type(odd) {
  background: var(--alt-rows-colour);
}
.data-40k-10e .unit .data_container .data .weapons .melee .weapon .line {
  display: grid;
  grid-template-columns: 7fr 2fr repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.data-40k-10e .unit .data_container .data .weapons .melee .weapon .line .value {
  color: black;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: normal;
  z-index: 2;
}
.data-40k-10e .unit .data_container .data.back .wargear_container {
  height: auto !important;
  padding-left: 0px;
  padding-right: 0px;
}
.data-40k-10e .unit .data_container .data.back .wargear_container .content {
  grid-template-rows: auto !important;
  grid-template-columns: 1fr !important;
  grid-auto-flow: row !important;
}
.data-40k-10e .unit .data_container .data.back .composition,
.data-40k-10e .unit .data_container .data.back .loadout,
.data-40k-10e .unit .data_container .data.back .transport,
.data-40k-10e .unit .data_container .data.back .leader {
  padding-left: 14px !important;
}
.data-40k-10e .unit .data_container .data .wargear_container {
  height: 500px;
  border-right: 2px solid var(--header-colour);
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.data-40k-10e .unit .data_container .data .wargear_container .wargear {
  position: relative;
}
.data-40k-10e .unit .data_container .data .wargear_container .wargear .heading {
  background-color: var(--header-colour);
  padding-left: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-bottom: 4px;
}
.data-40k-10e .unit .data_container .data .wargear_container .wargear .heading .title {
  font-family: var(--title-font-family);
  text-transform: uppercase;
  color: var(--header-text-colour);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
}
.data-40k-10e .unit .data_container .data .wargear_container .wargear .explanation {
  margin-left: 16px;
  padding-right: 16px;
  font-size: 0.7rem;
  color: black;
  font-weight: 400;
  padding-left: 4px;
}
.data-40k-10e .unit .data_container .data .wargear_container .wargear .explanation:before {
  content: "*";
}
.data-40k-10e .unit .data_container .data .wargear_container .wargear .content {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
}
.data-40k-10e .unit .data_container .data .wargear_container .wargear .content .item {
  align-self: flex-start;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 1.1rem;
  white-space: pre-wrap;
}
.data-40k-10e .unit .data_container .data .wargear_container .wargear .content .item:before {
  content: "■";
}
.data-40k-10e .unit .data_container .data .wargear_container .wargear .content .item .description {
  font-size: 0.8rem;
  color: black;
  font-weight: 400;
  padding-left: 4px;
}
.data-40k-10e .unit .data_container .data .extra {
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.data-40k-10e .unit .data_container .data .extra .invul_container {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
}
.data-40k-10e .unit .data_container .data .extra .invul_container .info {
  color: black;
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: normal;
  font-style: italic;
  margin-top: -12px;
  padding-left: 8px;
  padding-right: 32px;
}
.data-40k-10e .unit .data_container .data .extra .invul_container .invul {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.data-40k-10e .unit .data_container .data .extra .invul_container .invul::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 22px;
  background-color: var(--header-colour);
  top: calc(50% - 11px);
}
.data-40k-10e .unit .data_container .data .extra .invul_container .invul .title {
  text-transform: uppercase;
  color: var(--header-text-colour);
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
  padding-left: 8px;
}
.data-40k-10e .unit .data_container .data .extra .invul_container .invul .value_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  z-index: 1;
  mask: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/invul_shield.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background-color: var(--header-colour);
}
.data-40k-10e .unit .data_container .data .extra .invul_container .invul .value_container .value {
  text-align: center;
  height: 44px;
  width: 45px;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--stat-text-colour);
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: white;
  mask: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/invul_shield.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}
.data-40k-10e .unit .data_container .data .extra .abilities .heading {
  background-color: var(--header-colour);
  padding-left: 8px;
}
.data-40k-10e .unit .data_container .data .extra .abilities .heading .title {
  text-transform: uppercase;
  color: var(--header-text-colour);
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
}
.data-40k-10e .unit .data_container .data .extra .abilities .ability {
  padding: 4px;
  border-bottom: 1px dotted #636567;
  line-height: 1rem;
}
.data-40k-10e .unit .data_container .data .extra .abilities .ability .title {
  text-transform: uppercase;
  font-size: 0.7rem;
  color: black;
  font-weight: 300;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .unit .data_container .data .extra .abilities .ability .title::after {
  content: ":";
}
.data-40k-10e .unit .data_container .data .extra .abilities .ability .value {
  text-transform: capitalize;
  font-size: 0.7rem;
  color: black;
  font-weight: 600;
}
.data-40k-10e .unit .data_container .data .extra .abilities .ability .description {
  font-size: 0.7rem;
  color: black;
  font-weight: 400;
  padding-left: 4px;
}
.data-40k-10e .unit .data_container .data .extra .abilities .ability .name {
  text-transform: capitalize;
  font-size: 0.7rem;
  color: black;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .unit .data_container .data .extra .abilities .ability .name::after {
  content: ":";
}
.data-40k-10e .unit .data_container .data .extra .composition_container .heading {
  background-color: var(--header-colour);
  padding-left: 8px;
  margin-bottom: 4px;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .heading .title {
  text-transform: uppercase;
  color: var(--header-text-colour);
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .composition {
  padding: 2px;
  line-height: 1rem;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .composition .description {
  font-size: 0.8rem;
  color: black;
  font-weight: 600;
  padding-left: 4px;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .composition .description:before {
  content: "■ ";
}
.data-40k-10e .unit .data_container .data .extra .composition_container .leader {
  padding: 2px;
  line-height: 1rem;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .leader .description {
  font-size: 0.7rem;
  color: black;
  font-weight: 400;
  white-space: pre-wrap;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .leader .value {
  font-size: 0.7rem;
  color: black;
  font-weight: 600;
  white-space: pre-wrap;
  margin-left: 4px;
}
@media screen {
  .data-40k-10e .unit .data_container .data .extra .composition_container .leader .value::before {
    content: "[ ";
  }
  .data-40k-10e .unit .data_container .data .extra .composition_container .leader .value::after {
    content: " ]";
  }
}
.data-40k-10e .unit .data_container .data .extra .composition_container .leader .value:hover {
  text-decoration: underline;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .ledBy {
  padding: 2px;
  padding-left: 14px !important;
  line-height: 1rem;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .ledBy .description {
  font-size: 0.7rem;
  color: black;
  font-weight: 400;
  white-space: pre-wrap;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .ledBy .value {
  font-size: 0.7rem;
  color: black;
  font-weight: 600;
  white-space: pre-wrap;
  margin-left: 4px;
}
@media screen {
  .data-40k-10e .unit .data_container .data .extra .composition_container .ledBy .value::before {
    content: "[ ";
  }
  .data-40k-10e .unit .data_container .data .extra .composition_container .ledBy .value::after {
    content: " ]";
  }
}
.data-40k-10e .unit .data_container .data .extra .composition_container .ledBy .value:hover {
  text-decoration: underline;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .transport {
  padding: 2px;
  line-height: 1rem;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .transport .description {
  font-size: 0.7rem;
  color: black;
  font-weight: 400;
  white-space: pre-wrap;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .loadout {
  padding-left: 4px;
  padding-right: 4px;
  line-height: 1.2rem;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .loadout .description {
  font-size: 0.7rem;
  color: black;
  font-weight: 400;
  text-transform: capitalize;
}
.data-40k-10e .unit .data_container .data .extra .composition_container .loadout .name {
  font-size: 0.7rem;
  color: black;
  font-weight: 600;
  padding-right: 4px;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .unit .data_container .data .extra .composition_container .loadout .name::after {
  content: ":";
}
.data-40k-10e .unit .data_container .data .extra .damaged .description {
  font-size: 0.7rem;
  color: black;
  font-weight: 400;
  line-height: 1.1rem;
  padding-left: 4px;
  padding-top: 4px;
}
.data-40k-10e .unit .data_container .data .extra .damaged .heading {
  background-color: var(--header-colour);
  padding-left: 8px;
  position: relative;
}
.data-40k-10e .unit .data_container .data .extra .damaged .heading .title {
  text-transform: uppercase;
  color: var(--header-text-colour);
  font-size: 1rem;
  font-weight: 600;
  z-index: 2;
  padding-left: 16px;
}
.data-40k-10e .unit .data_container .data .extra .damaged .heading .title::before {
  content: "";
  position: absolute;
  left: 6px;
  top: 0;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Toughness.svg");
  background-repeat: no-repeat;
  filter: invert(87%) sepia(100%) saturate(0%) hue-rotate(303deg) brightness(104%) contrast(101%);
  width: 20px;
  height: 20px;
  margin-top: 2px;
  scale: 0.8;
}
.data-40k-10e .unit .special .description-container {
  line-height: 1.1rem;
  padding-left: 4px;
  padding-top: 4px;
}
.data-40k-10e .unit .special .description-container .description {
  font-size: 0.7rem;
  color: black;
  font-weight: 400;
}
.data-40k-10e .unit .special .heading {
  background-color: var(--header-colour);
  padding-left: 8px;
  position: relative;
  line-height: 1.5rem;
}
.data-40k-10e .unit .special .heading .title {
  text-transform: uppercase;
  color: var(--header-text-colour);
  font-size: 1rem;
  font-weight: 600;
  z-index: 2;
}
.data-40k-10e .unit .special .ability {
  padding: 4px;
  border-bottom: 1px dotted #636567;
  line-height: 1rem;
}
.data-40k-10e .unit .special .ability .title {
  text-transform: uppercase;
  font-size: 0.7rem;
  color: black;
  font-weight: 300;
}
.data-40k-10e .unit .special .ability .title::after {
  content: ":";
}
.data-40k-10e .unit .special .ability .value {
  text-transform: capitalize;
  font-size: 0.7rem;
  color: black;
  font-weight: 600;
}
.data-40k-10e .unit .special .ability .description {
  font-size: 0.7rem;
  color: black;
  font-weight: 400;
  padding-left: 4px;
}
.data-40k-10e .unit .special .ability .name {
  text-transform: capitalize;
  font-size: 0.7rem;
  color: black;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .unit .special .ability .name::after {
  content: ":";
}
.data-40k-10e .unit .footer {
  height: 64px;
  width: 1000px;
  border: 2px solid var(--header-colour);
  background-color: var(--text-background-colour);
  position: absolute;
  bottom: 32px;
  display: grid;
  grid-template-columns: 670px auto;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.data-40k-10e .unit .footer .keywords {
  border-right: 2px solid var(--header-colour);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  position: relative;
  padding-right: 48px;
  background-color: var(--keywords-background-colour);
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .unit .footer .keywords .title {
  text-transform: uppercase;
  font-size: 1rem;
  color: black;
  font-weight: 400;
  padding-right: 4px;
}
.data-40k-10e .unit .footer .keywords .title::after {
  content: ":";
}
.data-40k-10e .unit .footer .keywords .value {
  text-transform: capitalize;
  font-size: 1rem;
  color: black;
  font-weight: 600;
}
.data-40k-10e .unit .footer .factions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-left: 48px;
  background-color: var(--background-colour);
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .unit .footer .factions .title {
  text-transform: uppercase;
  font-size: 1rem;
  color: var(--faction-text-colour);
  font-weight: 400;
  padding-right: 4px;
  line-height: 1.1rem;
}
.data-40k-10e .unit .footer .factions .title::after {
  content: ":";
}
.data-40k-10e .unit .footer .factions .value {
  text-transform: capitalize;
  font-size: 1rem;
  color: var(--faction-text-colour);
  font-weight: 600;
  line-height: 1.1rem;
}
.data-40k-10e .unit .faction {
  height: 64px;
  width: 64px;
  border: 2px solid var(--header-colour);
  background-color: var(--rows-colour);
  position: absolute;
  bottom: 32px;
  left: 677px;
  rotate: 45deg;
  overflow: hidden;
}
.data-40k-10e .unit .faction .AS {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .AC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .AdM {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AdM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .AE {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .AM {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CD {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CD.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .QT {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CSM {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CSM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .DG {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .DRU {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DRU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .GC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .GK {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .HTL {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HTL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .QI {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .INQ {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/INQ.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .NEC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/NEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .OA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/OA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .ORK {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/ORK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .RaH {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RaH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .RT {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .SM {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/SM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .TAU {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TAU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .TS {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .TL {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .TYR {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TYR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .UN {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/UN.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHAC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHAC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHBT {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHBA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHBR {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHCA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHCR {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHCF {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHDA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHDW {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHEM {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHEX {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEX.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHES {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHES.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHFA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHFH {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHFT {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHHG {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHHG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHIF {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHIH {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHLA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHLA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHMW {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHME {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHME.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHMI {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHC0 {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHC0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHNO {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHNO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHRA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHRG {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHRS {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHSA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHST {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHST.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHSO {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHSW {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHSP {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSP.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHTC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHTK {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHUL {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHUL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHWS {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .CHWO {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .LGAL {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGAL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .LGBL {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGBL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .LGCB {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGCB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .LGEC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .LGIW {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGIW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .LGNL {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGNL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .LGRC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGRC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .LGWB {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .LGWE {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .HAR {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HAR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .WE {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/WE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .AoI {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AoI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .GSC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GSC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .LoV {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LoV.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .AoI {
  scale: 1.3;
}
.data-40k-10e .unit .faction .CHDW {
  scale: 0.7;
}
.data-40k-10e .unit .faction .DRU {
  scale: 1;
}
.data-40k-10e .unit .faction .GK {
  scale: 1;
}
.data-40k-10e .unit .faction .ORK {
  scale: 1;
}
.data-40k-10e .unit .faction .SM {
  scale: 1.4;
}
.data-40k-10e .unit .faction .CHSW {
  scale: 1;
}
.data-40k-10e .unit .faction .TYR {
  scale: 1;
}
.data-40k-10e .unit .faction .LoV {
  scale: 1;
}
.data-40k-10e .unit .faction .WE {
  scale: 1;
}
.data-40k-10e .unit .faction .AE {
  scale: 1;
}
.data-40k-10e .unit .faction .AM {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 1.3;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .unit .faction .basic {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit {
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-size: 1rem;
}
.data-40k-10e .viewer .unit .header {
  width: 100%;
  height: auto;
  min-height: 110px;
  background-color: var(--background-colour);
  position: relative;
  display: block;
}
.data-40k-10e .viewer .unit .header.back {
  min-height: 65px;
}
.data-40k-10e .viewer .unit .header.back::after {
  position: absolute;
  content: "";
  top: 8px;
  left: 0px;
  width: 100%;
  height: 85px;
  background-color: var(--banner-colour);
}
.data-40k-10e .viewer .unit .header .header_container {
  grid-area: c1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 12px;
  padding-left: 8px;
}
@media only screen and (max-width: 600px) {
  .data-40k-10e .viewer .unit .header .header_container .legends {
    position: absolute;
    right: 16px;
    top: 12px;
    font-size: 1rem;
    z-index: 10;
    color: var(--faction-text-colour);
    font-weight: 600;
    font-family: "EB Garamond", "serif";
  }
  .data-40k-10e .viewer .unit .header .header_container .legends::before {
    content: "Legends";
  }
}
@media only screen and (min-width: 600px) {
  .data-40k-10e .viewer .unit .header .header_container .legends {
    position: absolute;
    top: 38px;
    right: 110px;
    z-index: 10;
    font-size: 1.3rem;
    font-weight: 600;
    font-family: "EB Garamond", "serif";
    color: var(--faction-text-colour);
  }
  .data-40k-10e .viewer .unit .header .header_container .legends::before {
    content: "Warhammer Legends";
  }
}
.data-40k-10e .viewer .unit .header .header_container .name_container {
  flex-basis: 100%;
  display: flex;
  align-items: flex-end;
}
.data-40k-10e .viewer .unit .header .header_container .name_container .name {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 800;
  color: white;
  z-index: 2;
  line-height: 1.5rem;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .viewer .unit .header .header_container .name_container .subname {
  padding-left: 8px;
  text-transform: uppercase;
  font-style: italic;
  color: white;
  font-size: 1rem;
  z-index: 2;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .viewer .unit .header .header_container .points_container {
  padding-top: 0px;
  padding-left: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 2;
}
.data-40k-10e .viewer .unit .header .header_container .points_container .points {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
  z-index: 2;
  line-height: 1rem;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .viewer .unit .header .stats_container {
  padding-left: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2px;
  grid-template-areas: "column-7 column-7 column-7 column-7 column-7 column-7" "column-1 column-2 column-3 column-4 column-5 column-6";
}
.data-40k-10e .viewer .unit .header .stats_container .invul_container {
  padding-left: 0px;
  grid-column-start: column-3;
  grid-column-end: column-5;
  margin-left: -4px;
}
.data-40k-10e .viewer .unit .header .stats_container .stat:nth-child(1) {
  grid-area: column-1;
}
.data-40k-10e .viewer .unit .header .stats_container .stat:nth-child(2) {
  grid-area: column-2;
}
.data-40k-10e .viewer .unit .header .stats_container .stat:nth-child(3) {
  grid-area: column-3;
}
.data-40k-10e .viewer .unit .header .stats_container .stat:nth-child(4) {
  grid-area: column-4;
}
.data-40k-10e .viewer .unit .header .stats_container .stat:nth-child(5) {
  grid-area: column-5;
}
.data-40k-10e .viewer .unit .header .stats_container .stat:nth-child(6) {
  grid-area: column-6;
}
.data-40k-10e .viewer .unit .header .stats_container .name {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  z-index: 2;
  line-height: 1.5rem;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  height: 1.2rem;
  width: 100%;
  grid-area: column-7;
}
.data-40k-10e .viewer .unit .header .stats_container .stat {
  z-index: 2;
  position: relative;
  flex-direction: row;
  justify-content: center;
}
.data-40k-10e .viewer .unit .header .stats_container .stat .caption {
  text-align: center;
  font-size: 0.8rem;
  color: var(--title-text-colour);
  font-weight: 600;
  width: 34px;
  height: 12px;
}
.data-40k-10e .viewer .unit .header .stats_container .stat .damageTable {
  position: absolute;
  bottom: -14px;
  height: 24px;
  width: 24px;
  left: 10px;
  border-radius: 32px;
  background-color: var(--header-colour);
}
.data-40k-10e .viewer .unit .header .stats_container .stat .damageTable::after {
  content: " ";
  position: absolute;
  filter: invert(87%) sepia(100%) saturate(0%) hue-rotate(303deg) brightness(104%) contrast(101%);
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Toughness.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
  width: 100%;
  scale: 0.75;
}
.data-40k-10e .viewer .unit .header .stats_container .stat .damageTable {
  position: absolute;
  bottom: -14px;
  height: 24px;
  width: 24px;
  left: 10px;
  border-radius: 32px;
  background-color: var(--header-colour);
}
.data-40k-10e .viewer .unit .header .stats_container .stat .damageTable::after {
  content: " ";
  position: absolute;
  filter: invert(87%) sepia(100%) saturate(0%) hue-rotate(303deg) brightness(104%) contrast(101%);
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Toughness.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
  width: 100%;
  scale: 0.75;
}
@media only screen and (max-width: 600px) {
  .data-40k-10e .viewer .unit .header .stats_container .stat .damageTable {
    left: 5px;
  }
}
.data-40k-10e .viewer .unit .header .stats_container .stat .value_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--header-colour);
  height: 34px;
  width: 34px;
  z-index: 1;
  clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%, 10% 0);
}
.data-40k-10e .viewer .unit .header .stats_container .stat .value_container .value {
  text-align: center;
  height: 30px;
  width: 30px;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--header-colour);
  z-index: 2;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%, 10% 0);
}
.data-40k-10e .viewer .unit .header .stats_container .stat .value_container.wide {
  width: 60px;
}
.data-40k-10e .viewer .unit .header .stats_container .stat .value_container.wide .value {
  width: 54px;
}
.data-40k-10e .viewer .unit .header::after {
  position: absolute;
  content: "";
  top: 8px;
  left: 0px;
  width: 100%;
  height: 90px;
  background-color: var(--banner-colour);
  clip-path: polygon(0% 0%, 0% 100%, 42% 100%, 52% 50%, 100% 50%, 100% 0%);
}
.data-40k-10e .viewer .unit .data_container {
  height: auto;
  width: 100%;
  background-color: var(--header-colour);
  clip-path: initial;
}
.data-40k-10e .viewer .unit .data_container .data {
  top: 0;
  margin-top: -4px;
  height: auto;
  width: 100%;
  background-color: var(--text-background-colour);
  clip-path: initial;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  display: grid;
  grid-template-areas: " weapons " " extra " " multi-data ";
}
.data-40k-10e .viewer .unit .data_container .data .weapons {
  grid-area: weapons;
}
.data-40k-10e .viewer .unit .data_container .data .multi-data {
  grid-area: multi-data;
  border-right: none;
}
.data-40k-10e .viewer .unit .data_container .data .extra {
  grid-area: extra;
  padding-bottom: 16px !important;
}
.data-40k-10e .viewer .unit .data_container .data .extra div:last-child {
  padding-bottom: 0px;
}
.data-40k-10e .viewer .unit .data_container .data .weapons {
  height: auto;
  border-right: 0px solid var(--header-colour);
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged {
  position: relative;
}
@media only screen and (max-width: 600px) {
  .data-40k-10e .viewer .unit .data_container .data .weapons .ranged::before {
    content: "";
    position: absolute;
    left: 4px;
    top: 0;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/BallisticSkill.svg");
    background-repeat: none;
    filter: invert(87%) sepia(100%) saturate(0%) hue-rotate(303deg) brightness(104%) contrast(101%);
    width: 16px;
    height: 16px;
    margin-top: 2px;
  }
}
@media only screen and (min-width: 600px) {
  .data-40k-10e .viewer .unit .data_container .data .weapons .ranged::before {
    content: "";
    position: absolute;
    left: 4px;
    top: 0;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/BallisticSkill.svg");
    background-repeat: none;
    filter: invert(87%) sepia(100%) saturate(0%) hue-rotate(303deg) brightness(104%) contrast(101%);
    width: 24px;
    height: 24px;
  }
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .heading {
  background-color: var(--header-colour);
  padding-left: 0px;
  display: grid;
  grid-template-columns: 7fr 2fr repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding-right: 8px;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .heading .title {
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .heading .title:first-child {
  margin-left: 24px;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon {
  padding-left: 8px;
  border-bottom: 1px dotted #636567;
  padding-right: 8px;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon:nth-of-type(even) {
  background: var(--rows-colour);
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon:nth-of-type(odd) {
  background: var(--alt-rows-colour);
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon .line {
  display: grid;
  grid-template-columns: 7fr 2fr repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-areas: " column-1 column-2 column-3 column-4 column-5 column-6 column-7 " " column-8 column-8 column-8 column-8 column-8 column-8 column-8";
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon .line .value:nth-child(1) {
  grid-area: column-1;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon .line .value:nth-child(2) {
  grid-area: column-2;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon .line .value:nth-child(3) {
  grid-area: column-3;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon .line .value:nth-child(4) {
  grid-area: column-4;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon .line .value:nth-child(5) {
  grid-area: column-5;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon .line .value:nth-child(6) {
  grid-area: column-6;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon .line .value:nth-child(7) {
  grid-area: column-7;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon .line .keyword:nth-child(8) {
  grid-area: column-8;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon .line .value {
  color: black;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: normal;
  z-index: 2;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon .line .value .name {
  white-space: pre-line;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .ranged .weapon .line .value .keyword {
  white-space: nowrap;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee {
  position: relative;
}
@media only screen and (max-width: 600px) {
  .data-40k-10e .viewer .unit .data_container .data .weapons .melee::before {
    content: "";
    position: absolute;
    left: 4px;
    top: 0;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Melee.svg");
    background-repeat: none;
    filter: invert(87%) sepia(100%) saturate(0%) hue-rotate(303deg) brightness(104%) contrast(101%);
    width: 16px;
    height: 16px;
    margin-top: 4px;
  }
}
@media only screen and (min-width: 600px) {
  .data-40k-10e .viewer .unit .data_container .data .weapons .melee::before {
    content: "";
    position: absolute;
    left: 4px;
    top: 0;
    background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Melee.svg");
    background-repeat: none;
    filter: invert(87%) sepia(100%) saturate(0%) hue-rotate(303deg) brightness(104%) contrast(101%);
    width: 20px;
    height: 20px;
    margin-top: 3px;
  }
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .heading {
  background-color: var(--header-colour);
  padding-left: 0px;
  display: grid;
  grid-template-columns: 7fr 2fr repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding-right: 8px;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .heading .title {
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
  padding-top: 2px;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .heading .title:first-child {
  margin-left: 24px;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .special .ability {
  padding-left: 8px !important;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon {
  padding-left: 8px;
  border-bottom: 1px dotted #636567;
  padding-right: 8px;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon:nth-of-type(even) {
  background: var(--rows-colour);
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon:nth-of-type(odd) {
  background: var(--alt-rows-colour);
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon .line {
  display: grid;
  grid-template-columns: 7fr 2fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: " column-1 column-2 column-3 column-4 column-5 column-6 column-7 " " column-8 column-8 column-8 column-8 column-8 column-8 column-8";
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon .line .value:nth-child(1) {
  grid-area: column-1;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon .line .value:nth-child(2) {
  grid-area: column-2;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon .line .value:nth-child(3) {
  grid-area: column-3;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon .line .value:nth-child(4) {
  grid-area: column-4;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon .line .value:nth-child(5) {
  grid-area: column-5;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon .line .value:nth-child(6) {
  grid-area: column-6;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon .line .value:nth-child(7) {
  grid-area: column-7;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon .line .value {
  color: black;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: normal;
  z-index: 2;
}
.data-40k-10e .viewer .unit .data_container .data .weapons .melee .weapon .line .value .keyword {
  white-space: nowrap;
  grid-area: column-8;
}
.data-40k-10e .viewer .unit .data_container .data .extra {
  padding: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.data-40k-10e .viewer .unit .data_container .data .extra .invul_container {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
}
.data-40k-10e .viewer .unit .data_container .data .extra .invul_container .info {
  color: black;
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: normal;
  font-style: italic;
  margin-top: -12px;
  padding-left: 8px;
  padding-right: 32px;
}
.data-40k-10e .viewer .unit .data_container .data .extra .invul_container .invul {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.data-40k-10e .viewer .unit .data_container .data .extra .invul_container .invul::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 22px;
  background-color: var(--header-colour);
  top: calc(50% - 11px);
}
.data-40k-10e .viewer .unit .data_container .data .extra .invul_container .invul .title {
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
  padding-left: 8px;
}
.data-40k-10e .viewer .unit .data_container .data .extra .invul_container .invul .value_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  z-index: 1;
  mask: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/invul_shield.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background-color: var(--header-colour);
}
.data-40k-10e .viewer .unit .data_container .data .extra .invul_container .invul .value_container .value {
  text-align: center;
  height: 44px;
  width: 45px;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--header-colour);
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: white;
  mask: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/invul_shield.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}
.data-40k-10e .viewer .unit .data_container .data .extra .abilities .heading {
  background-color: var(--header-colour);
  padding-left: 8px;
}
.data-40k-10e .viewer .unit .data_container .data .extra .abilities .heading .title {
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
}
.data-40k-10e .viewer .unit .data_container .data .extra .abilities .ability {
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px dotted #636567;
  line-height: 1rem;
}
.data-40k-10e .viewer .unit .data_container .data .extra .abilities .ability .title {
  text-transform: uppercase;
  font-size: 0.8rem;
  color: black;
  font-weight: 300;
}
.data-40k-10e .viewer .unit .data_container .data .extra .abilities .ability .title::after {
  content: ":";
}
.data-40k-10e .viewer .unit .data_container .data .extra .abilities .ability .value {
  text-transform: capitalize;
  font-size: 0.8rem;
  color: black;
  font-weight: 600;
}
.data-40k-10e .viewer .unit .data_container .data .extra .abilities .ability .description {
  font-size: 0.8rem;
  color: black;
  font-weight: 400;
  padding-left: 4px;
}
.data-40k-10e .viewer .unit .data_container .data .extra .abilities .ability .name {
  text-transform: capitalize;
  font-size: 0.8rem;
  color: black;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .viewer .unit .data_container .data .extra .abilities .ability .name::after {
  content: ":";
}
.data-40k-10e .viewer .unit .data_container .data .extra .damaged .description {
  font-size: 0.8rem;
  color: black;
  font-weight: 400;
  line-height: 1.1rem;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
}
.data-40k-10e .viewer .unit .data_container .data .extra .damaged .heading {
  background-color: var(--header-colour);
  padding-left: 8px;
  position: relative;
}
.data-40k-10e .viewer .unit .data_container .data .extra .damaged .heading .title {
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  z-index: 2;
  padding-left: 16px;
}
.data-40k-10e .viewer .unit .data_container .data .extra .damaged .heading .title::before {
  content: "";
  position: absolute;
  left: 6px;
  top: 0;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Toughness.svg");
  background-repeat: no-repeat;
  filter: invert(87%) sepia(100%) saturate(0%) hue-rotate(303deg) brightness(104%) contrast(101%);
  width: 20px;
  height: 20px;
  margin-top: 2px;
  scale: 0.8;
}
.data-40k-10e .viewer .unit .special .description {
  font-size: 0.8rem;
  color: black;
  font-weight: 400;
  line-height: 1.1rem;
  padding-left: 16px;
  padding-top: 4px;
}
.data-40k-10e .viewer .unit .special .heading {
  background-color: var(--header-colour);
  padding-left: 8px;
  position: relative;
}
.data-40k-10e .viewer .unit .special .heading .title {
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  z-index: 2;
}
.data-40k-10e .viewer .unit .special .ability {
  padding: 4px;
  border-bottom: 1px dotted #636567;
  line-height: 1rem;
}
.data-40k-10e .viewer .unit .special .ability .title {
  text-transform: uppercase;
  font-size: 0.8rem;
  color: black;
  font-weight: 300;
}
.data-40k-10e .viewer .unit .special .ability .title::after {
  content: ":";
}
.data-40k-10e .viewer .unit .special .ability .value {
  text-transform: capitalize;
  font-size: 0.8rem;
  color: black;
  font-weight: 600;
}
.data-40k-10e .viewer .unit .special .ability .description {
  font-size: 0.8rem;
  color: black;
  font-weight: 400;
  padding-left: 8px;
  padding-right: 8px;
}
.data-40k-10e .viewer .unit .special .ability .name {
  text-transform: capitalize;
  font-size: 0.8rem;
  color: black;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}
.data-40k-10e .viewer .unit .special .ability .name::after {
  content: ":";
}
.data-40k-10e .viewer .unit .footer {
  height: auto;
  width: 100%;
  border: 0px solid var(--header-colour);
  background-color: var(--text-background-colour);
  position: initial;
  grid-template-columns: 1fr;
}
.data-40k-10e .viewer .unit .footer .keywords {
  width: 100%;
  border-right: 0px solid var(--header-colour);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0px;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  padding-right: 0px;
  grid-template-areas: "column-1" "column-2";
  padding-left: 0px;
  background-color: var(--rows-colour);
}
.data-40k-10e .viewer .unit .footer .keywords .title {
  padding-left: 8px;
  margin-top: 16px;
  padding-right: 4px;
  grid-area: column-1;
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
  width: 100%;
  background-color: var(--header-colour);
}
.data-40k-10e .viewer .unit .footer .keywords .title::after {
  content: "";
}
.data-40k-10e .viewer .unit .footer .keywords .value {
  padding: 8px;
  text-transform: capitalize;
  font-size: 0.7rem;
  color: black;
  font-weight: 600;
  width: 100%;
  grid-area: column-2;
}
.data-40k-10e .viewer .unit .footer .factions {
  width: 100%;
  border-right: 0px solid var(--header-colour);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0px;
  padding-right: 0px;
  grid-template-areas: "column-1" "column-2";
  padding-left: 0px;
  background-color: var(--rows-colour);
}
.data-40k-10e .viewer .unit .footer .factions .title {
  padding: 4px;
  padding-left: 8px;
  margin-top: 0px;
  grid-area: column-1;
  text-transform: uppercase;
  color: var(--faction-text-colour);
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
  z-index: 2;
  width: 100%;
  background-color: var(--header-colour);
}
.data-40k-10e .viewer .unit .footer .factions .title::after {
  content: "";
}
.data-40k-10e .viewer .unit .footer .factions .value {
  padding: 8px;
  text-transform: capitalize;
  font-size: 0.7rem;
  color: black;
  font-weight: 600;
  width: 100%;
  grid-area: column-2;
}
.data-40k-10e .viewer .unit .faction {
  display: none;
  height: 64px;
  width: 64px;
  border: 2px solid var(--header-colour);
  background-color: var(--rows-colour);
  position: absolute;
  bottom: 32px;
  left: 677px;
  rotate: 45deg;
  overflow: hidden;
}
.data-40k-10e .viewer .unit .faction .AS {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .AC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .AdM {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AdM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .AE {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .AM {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CD {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CD.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .QT {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CSM {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CSM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .DG {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .DRU {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DRU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .GC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .GK {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .HTL {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HTL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .QI {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .INQ {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/INQ.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .NEC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/NEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .OA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/OA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .ORK {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/ORK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .RaH {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RaH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .RT {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .SM {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/SM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .TAU {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TAU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .TS {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .TL {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .TYR {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TYR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .UN {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/UN.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHAC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHAC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHBT {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHBA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHBR {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHCA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHCR {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHCF {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHDA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHDW {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHEM {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHEX {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEX.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHES {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHES.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHFA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHFH {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHFT {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHHG {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHHG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHIF {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHIH {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHLA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHLA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHMW {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHME {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHME.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHMI {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHC0 {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHC0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHNO {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHNO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHRA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHRG {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHRS {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHSA {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHST {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHST.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHSO {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHSW {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHSP {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSP.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHTC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHTK {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHUL {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHUL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHWS {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .CHWO {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .LGAL {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGAL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .LGBL {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGBL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .LGCB {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGCB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .LGEC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .LGIW {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGIW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .LGNL {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGNL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .LGRC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGRC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .LGWB {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .LGWE {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .HAR {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HAR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .WE {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/WE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .AoI {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AoI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .GSC {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GSC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .LoV {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 0.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LoV.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .AM {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 1.8;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .viewer .unit .faction .basic {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  scale: 1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(100%) contrast(100%);
  rotate: -45deg;
}
.data-40k-10e .center {
  text-align: center;
}
.data-40k-10e .rule {
  cursor: pointer;
  text-transform: capitalize;
  color: black;
  font-weight: 600;
}
.data-40k-10e .rule .rule-button {
  font-size: inherit;
  height: 16px;
  padding: 0px;
}
@media screen {
  .data-40k-10e .rule .rule-button span::before {
    content: "[ ";
  }
  .data-40k-10e .rule .rule-button span::after {
    content: " ]";
  }
}
.data-40k-10e .rule:hover {
  text-decoration: underline;
}
.data-40k-10e .rule span {
  text-transform: capitalize;
  color: black;
  font-weight: 600;
}
.data-40k-10e .keyword {
  text-transform: uppercase;
  color: var(--weapon-keyword-colour);
  font-size: 0.7rem;
  font-weight: 600;
  z-index: 2;
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
}
.data-40k-10e .keyword .keyword-button {
  text-transform: uppercase;
  color: var(--weapon-keyword-colour);
  font-size: 0.7rem;
  font-weight: 600;
  height: 16px;
  padding: 0px;
}
.data-40k-10e .keyword .keyword-button::after {
  content: ", ";
}
.data-40k-10e .keyword .keyword-button:first-child::before {
  content: "";
}
.data-40k-10e .keyword .keyword-button:last-child::after {
  content: "";
}
.data-40k-10e .keyword::after {
  content: "]";
}
.data-40k-10e .keyword::before {
  content: "[";
}
.basic_unit .page {
  width: inherit;
  display: grid;
  position: relative;
  padding: 8px;
  background-color: #d0d0d0;
}
.basic_unit .page.no-icons .movement::after {
  content: "M";
  font-size: 11px;
  display: block;
  margin-top: -2px;
}
.basic_unit .page.no-icons .weaponskill::after {
  content: "WS";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.basic_unit .page.no-icons .ballisticskill::after {
  content: "BS";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.basic_unit .page.no-icons .toughness::after {
  content: "T";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.basic_unit .page.no-icons .wounds::after {
  content: "W";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.basic_unit .page.no-icons .attacks::after {
  content: "A";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.basic_unit .page.no-icons .leadership::after {
  content: "Ld";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.basic_unit .page.no-icons .save::after {
  content: "Sv";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.basic_unit .page.no-icons .inv::after {
  content: "Inv";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.basic_unit .page.no-icons .ap::after {
  content: "AP";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.basic_unit .page.no-icons .dmg::after {
  content: "D";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.basic_unit .page.no-icons .range::after {
  content: "Rng";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.basic_unit .page.no-icons .strength::after {
  content: "S";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}
.basic_unit .page.no-icons .type::after {
  content: "Type";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  margin-left: -8px;
  text-align: center;
}
.basic_unit .page.no-icons .assault-weapon::after {
  content: "As";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.basic_unit .page.no-icons .grenade::after {
  content: "Gr";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.basic_unit .page.no-icons .dakka::after {
  content: "Dk";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.basic_unit .page.no-icons .heavy-weapon::after {
  content: "Hv";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.basic_unit .page.no-icons .melee::after {
  content: "Me";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.basic_unit .page.no-icons .pistol::after {
  content: "Ps";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.basic_unit .page.no-icons .rapid-fire::after {
  content: "Rf";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.basic_unit .page.no-icons .user::after {
  content: "Us";
  font-size: 11px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
.basic_unit .page.icons .movement {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Move.svg");
}
.basic_unit .page.icons .weaponskill {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/WeaponSkill.svg");
}
.basic_unit .page.icons .ballisticskill {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/BallisticSkill.svg");
}
.basic_unit .page.icons .toughness {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Toughness.svg");
}
.basic_unit .page.icons .wounds {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Wounds.svg");
}
.basic_unit .page.icons .attacks {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Attacks.svg");
}
.basic_unit .page.icons .leadership {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Leadership.svg");
}
.basic_unit .page.icons .save {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Save.svg");
}
.basic_unit .page.icons .inv {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Invuln.svg");
}
.basic_unit .page.icons .ap {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/ArmourPenetration.svg");
}
.basic_unit .page.icons .dmg {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Damage.svg");
}
.basic_unit .page.icons .range {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Range.svg");
}
.basic_unit .page.icons .strength {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Strength.svg");
}
.basic_unit .page.icons .type {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Type.svg");
}
.basic_unit .page.icons .assault-weapon {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Assault.svg");
}
.basic_unit .page.icons .grenade {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Grenade.svg");
}
.basic_unit .page.icons .dakka {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/RapidFire.svg");
}
.basic_unit .page.icons .heavy-weapon {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Heavy.svg");
}
.basic_unit .page.icons .melee {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Melee.svg");
}
.basic_unit .page.icons .pistol {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Pistol.svg");
}
.basic_unit .page.icons .rapid-fire {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/RapidFire.svg");
}
.basic_unit .page.icons .user {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/User.svg");
}
.basic_unit .page.playingcard {
  height: 8.89cm;
  width: 5.71cm;
}
.basic_unit .page.card {
  height: 10.8cm;
  width: 7.77cm;
}
.basic_unit .page.poker {
  height: 8.89cm;
  width: 6.35cm;
}
.basic_unit .page.sheet {
  height: 209.5mm;
  width: 148mm;
}
.basic_unit .page.a4 {
  height: 297mm;
  width: 210mm;
}
.basic_unit .page.letter {
  height: 279mm;
  width: 216mm;
}
.basic_unit .page.letter-half {
  height: 216mm;
  width: 139mm;
}
.basic_unit .page.secondary {
  height: 13.76cm;
  width: 7.77cm;
}
.basic_unit .page .value {
  vertical-align: middle;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  letter-spacing: 0px;
}
.basic_unit .page .frame {
  display: block;
  height: 100%;
  width: 100%;
  background: black;
  border-radius: 13.5px;
  background-color: #e2e2dd;
  overflow: hidden;
}
.basic_unit .page .frame .background {
  width: inherit;
}
.basic_unit .page .frame .AS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .AC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .AdM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AdM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .AE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .AM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CD::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CD.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .QT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CSM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CSM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .DG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .DRU::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DRU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .GC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .GK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .HTL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HTL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .QI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .INQ::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/INQ.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .NEC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/NEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .OA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/OA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .ORK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/ORK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .RaH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RaH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .RT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .SM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/SM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .TAU::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TAU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .TS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .TL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .TYR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TYR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .UN::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/UN.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHAC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHAC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHBT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHBA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHBR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHCA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHCR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHCF::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHDA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHDW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHEM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHEX::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEX.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHES::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHES.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHFA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHFH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHFT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHHG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHHG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHIF::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHIH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHLA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHLA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHMW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHME::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHME.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHMI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHC0::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHC0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHNO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHNO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHRA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHRG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHRS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHSA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHST::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHST.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHSO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHSW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHSP::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSP.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHTC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHTK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHUL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHUL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHWS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .CHWO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .LGAL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGAL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .LGBL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGBL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .LGCB::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGCB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .LGEC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .LGIW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGIW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .LGNL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGNL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .LGRC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGRC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .LGWB::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .LGWE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .HAR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HAR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .WE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/WE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .AoI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AoI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .GSC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GSC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .LoV::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LoV.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .AM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 55%;
  width: 100%;
  transform: translateY(-75px);
  height: 85px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .basic::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.basic_unit .page .frame .header {
  display: block;
  height: 45px;
  margin: 0 8px;
}
.basic_unit .page .frame .header .name {
  float: left;
  width: calc(100% - 44px);
  min-height: 35px;
  padding-top: 8px;
  line-height: 15px;
  text-transform: uppercase;
  vertical-align: middle;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -1px;
  border-bottom: 2px solid #000;
}
.basic_unit .page .frame .header .role {
  position: relative;
  float: left;
  width: 35px;
  height: 35px;
  padding-top: 8px;
  margin-right: 8px;
}
.basic_unit .page .frame .header .role div {
  height: 35px;
  width: 35px;
  position: absolute;
  bottom: 0;
}
.basic_unit .page .frame .header .role .hq {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/HQ.svg");
}
.basic_unit .page .frame .header .role .troops {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Troops.svg");
}
.basic_unit .page .frame .header .role .elites {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Elites.svg");
}
.basic_unit .page .frame .header .role .fast-attack {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Fast-Attack.svg");
}
.basic_unit .page .frame .header .role .dedicated-transport {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Dedicated-Transport.svg");
}
.basic_unit .page .frame .header .role .flyer {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Flyer.svg");
}
.basic_unit .page .frame .header .role .heavy-support {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Heavy-Support.svg");
}
.basic_unit .page .frame .header .role .fortification {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Fortification.svg");
}
.basic_unit .page .frame .header .role .lord-of-war {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Lord-of-War.svg");
}
.basic_unit .page .frame .header .role .unknown {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Unknown.svg");
}
.basic_unit .page .frame .description {
  background: #dee3e0;
  margin: 4px 5px;
  padding: 0 4px;
  vertical-align: middle;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 700px;
  text-align: justify;
  letter-spacing: 0px;
  word-spacing: -1px;
  line-height: 14px;
}
.basic_unit .page .frame .description table {
  width: 100%;
}
.basic_unit .page .frame .description table th {
  border: 1px solid #777273;
  padding: 4px;
  background-color: #b6beba;
}
.basic_unit .page .frame .description table td {
  border: 1px solid #777273;
  padding: 4px;
}
.basic_unit .page .frame .description p {
  margin-bottom: 0;
}
.basic_unit .page .frame .abilities {
  overflow: hidden;
  text-overflow: ellipsis;
}
.basic_unit .page .frame .profile {
  border-bottom: 2px solid #777273;
  margin: 0 8px;
}
.basic_unit .page .frame .profile .weapon_name {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
}
.basic_unit .page .frame .profile .weapon.nested {
  padding-left: 8px;
}
.basic_unit .page .frame .profile .weapon:nth-of-type(even) {
  background: #dee3e0;
}
.basic_unit .page .frame .profile .weapon:nth-of-type(even) + .weapon_desc {
  background: #dee3e0;
}
.basic_unit .page .frame .profile .weapon:nth-of-type(odd) + .weapon_desc {
  background: #fff;
}
.basic_unit .page .frame .profile .weapon:nth-of-type(odd) {
  background: #fff;
}
.basic_unit .page .frame .profile .weapon .weapon_profile {
  display: grid;
  grid-template-columns: 4fr 2fr 2fr 1fr 1fr 1fr;
  font-size: 11px;
}
.basic_unit .page .frame .profile .weapon .weapon_profile .weapon-icon {
  height: 11px;
  width: 11px;
  margin-right: 2px;
  margin-top: -2px;
  background-repeat: no-repeat;
}
.basic_unit .page .frame .profile .weapon .weapon_desc {
  padding: 0 12px;
  margin-bottom: 4px;
  vertical-align: middle;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 700px;
  text-align: justify;
  letter-spacing: 0px;
  word-spacing: 0px;
  line-height: 14px;
}
.basic_unit .page .frame .profile .weapon .weapon_desc p {
  margin-bottom: 0px;
}
.basic_unit .page .frame .profile .statline {
  display: grid;
  width: 9fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  text-align: centre;
  vertical-align: middle;
  font-weight: 600;
}
.basic_unit .page .frame .profile .statline:nth-child(even) {
  background: #dee3e0;
}
.basic_unit .page .frame .profile .statline:nth-child(odd) {
  background: #fff;
}
.basic_unit .page .frame .labels {
  font-weight: 600;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.basic_unit .page .frame .labels .label div {
  background-position: center;
}
.basic_unit .page .frame .weapons {
  grid-template-columns: 4fr 2fr 2fr 1fr 1fr 1fr;
  font-size: 12px;
}
.basic_unit .page .frame .heading {
  display: grid;
  background: #b6beba;
  margin: 4px 8px 0 8px;
  grid-template-rows: 14px;
  vertical-align: middle;
  border-bottom: 2px solid #777273;
}
.basic_unit .page .frame .heading .icon {
  display: block;
  height: 11px;
  width: 11px;
  margin: 0 auto;
  background-repeat: no-repeat;
}
.basic_unit .page .frame .footer {
  position: absolute;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
  background: #e2e2dd;
  z-index: 10;
  bottom: 0;
  transform: translateX(-50%) translateY(-10px);
  left: 50%;
  width: calc(100% - 32px);
}
.data-necromunda {
  --necromunda-font-family: -apple-system, BlinkMacSystemFont, "PassionOne", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.data-necromunda .ganger ö .page {
  padding: 8px;
  border-radius: 16px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/necromunda-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.data-necromunda .ganger .card {
  height: 75mm;
  width: 110mm;
}
.data-necromunda .ganger .large {
  height: 90mm;
  width: 130mm;
}
.data-necromunda .ganger .header {
  padding: 10px;
  height: 48px;
  border-top-left-radius: 8px;
  background-color: #ffffff88;
}
.data-necromunda .ganger .header .name {
  font-size: 20px;
  font-family: var(--necromunda-font-family);
  letter-spacing: 1px;
  text-transform: uppercase;
}
.data-necromunda .ganger .header .type {
  font-size: 16px;
  font-family: var(--necromunda-font-family);
  letter-spacing: 1px;
  margin-left: 8px;
}
.data-necromunda .ganger .cost {
  top: -4px;
  position: relative;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/necromunda-gear.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  text-align: center;
  padding-top: 8px;
  font-family: var(--necromunda-font-family);
  letter-spacing: 1px;
  text-transform: uppercase;
}
.data-necromunda .ganger .cost::after {
  position: absolute;
  width: 100%;
  content: "CREDITS";
  font-size: 9px;
  text-align: center;
  left: 0;
  bottom: 10px;
}
.data-necromunda .ganger .divider {
  margin-top: 6px;
}
.data-necromunda .ganger .wargear {
  display: grid;
  grid-template-columns: 2fr 10fr;
  column-gap: 4px;
  font-size: 10px;
  margin-top: 2px;
}
.data-necromunda .ganger .wargear .title {
  padding-left: 4px;
  background-color: #ffffff88;
  text-transform: uppercase;
}
.data-necromunda .ganger .wargear .options {
  padding-left: 4px;
  background-color: #ffffff88;
}
.data-necromunda .ganger .wargear p {
  margin-bottom: 0px;
}
.data-necromunda .ganger .rules {
  display: grid;
  grid-template-columns: 2fr 10fr;
  column-gap: 4px;
  font-size: 10px;
  margin-top: 2px;
}
.data-necromunda .ganger .rules .title {
  padding-left: 4px;
  background-color: #ffffff88;
  text-transform: uppercase;
}
.data-necromunda .ganger .rules .options {
  padding-left: 4px;
  background-color: #ffffff88;
}
.data-necromunda .ganger .rules p {
  margin-bottom: 0px;
}
.data-necromunda .ganger .abilities {
  display: grid;
  grid-template-columns: 2fr 10fr;
  column-gap: 4px;
  font-size: 10px;
  margin-top: 2px;
}
.data-necromunda .ganger .abilities .title {
  padding-left: 4px;
  background-color: #ffffff88;
  text-transform: uppercase;
}
.data-necromunda .ganger .abilities .options {
  padding-left: 4px;
  background-color: #ffffff88;
}
.data-necromunda .ganger .abilities p {
  margin-bottom: 0px;
}
.data-necromunda .ganger .weapons .legend {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 5fr;
  column-gap: 2px;
  font-size: 10px;
  font-weight: 600;
  padding-right: 2px;
  margin-top: 6px;
}
.data-necromunda .ganger .weapons .legend div {
  text-align: center;
}
.data-necromunda .ganger .weapons .legend :nth-child(1) {
  text-align: left;
  padding-left: 4px;
  border-right: 1px solid black;
}
.data-necromunda .ganger .weapons .legend :nth-child(3) {
  border-right: 1px solid black;
}
.data-necromunda .ganger .weapons .legend :nth-child(5) {
  border-right: 1px solid black;
}
.data-necromunda .ganger .weapons .legend :last-child {
  text-align: left;
  border-left: 1px solid black;
  padding-left: 4px;
}
.data-necromunda .ganger .weapons .line {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 5fr;
  column-gap: 2px;
  font-size: 10px;
  padding-right: 2px;
  padding-top: 2px;
}
.data-necromunda .ganger .weapons .line div {
  background-color: #ffffffaa;
  text-align: center;
}
.data-necromunda .ganger .weapons .line :nth-child(1) {
  text-align: left;
  border-right: 1px solid black;
  padding-right: 8px;
  padding-left: 4px;
}
.data-necromunda .ganger .weapons .line :nth-child(3) {
  border-right: 1px solid black;
}
.data-necromunda .ganger .weapons .line :nth-child(5) {
  border-right: 1px solid black;
}
.data-necromunda .ganger .weapons .line :last-child {
  text-align: left;
  border-left: 1px solid black;
  padding-left: 4px;
}
.data-necromunda .ganger .datasheet .legend {
  display: grid;
  grid-template-columns: 8fr 5fr;
  border-bottom: 2px solid #a05236;
  font-size: 11px;
}
.data-necromunda .ganger .datasheet .legend .stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2px;
  text-align: center;
  border-right: 2px solid #a05236;
  color: darkred;
  font-weight: 600;
  padding-right: 2px;
}
.data-necromunda .ganger .datasheet .legend .data {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  color: darkred;
  font-weight: 600;
  background-color: #c2ada399;
}
.data-necromunda .ganger .datasheet .line {
  display: grid;
  grid-template-columns: 8fr 5fr;
  font-size: 11px;
}
.data-necromunda .ganger .datasheet .line .data {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2px;
  text-align: center;
  background-color: #c2ada399;
  padding-right: 4px;
  padding-left: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.data-necromunda .ganger .datasheet .line .data div {
  background-color: #ffffffaa;
}
.data-necromunda .ganger .datasheet .line .stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2px;
  text-align: center;
  border-right: 2px solid #a05236;
  padding-right: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.data-necromunda .ganger .datasheet .line .stats div {
  background-color: #ffffffaa;
}
.data-necromunda .data-necromunda .stratagem .card {
  position: relative;
  height: 410px;
  width: 295px;
  background-color: #d0d0d0;
  padding: 8px;
}
.data-necromunda .data-necromunda .stratagem .frame {
  display: block;
  height: 100%;
  width: 100%;
  background: black;
  border-radius: 13.5px;
  background-color: #e2e2dd;
  overflow: hidden;
}
.data-necromunda .data-necromunda .stratagem .frame .AS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .AC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .AdM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AdM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .AE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .AM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CD::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CD.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .QT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CSM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CSM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .DG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .DRU::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/DRU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .GC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .GK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .HTL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HTL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .QI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/QI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .INQ::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/INQ.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .NEC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/NEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .OA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/OA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .ORK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/ORK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .RaH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RaH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .RT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/RT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .SM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/SM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .TAU::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TAU.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .TS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .TL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .TYR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/TYR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .UN::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/UN.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHAC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHAC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHBT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHBA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHBR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHBR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHCA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHCR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHCF::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHCF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHDA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHDW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHDW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHEM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHEX::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHEX.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHES::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHES.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHFA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHFH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHFT::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHFT.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHHG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHHG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHIF::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIF.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHIH::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHIH.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHLA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHLA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHMW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHME::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHME.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHMI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHMI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHC0::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHC0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHNO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHNO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHRA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHRG::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRG.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHRS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHRS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHSA::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSA.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHST::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHST.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHSO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHSW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHSP::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHSP.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHTC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHTK::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHTK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHUL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHUL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHWS::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWS.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .CHWO::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/CHWO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .LGAL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGAL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .LGBL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGBL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .LGCB::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGCB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .LGEC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGEC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .LGIW::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGIW.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .LGNL::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGNL.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .LGRC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGRC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .LGWB::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWB.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .LGWE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LGWE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .HAR::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/HAR.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .WE::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/WE.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .AoI::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AoI.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .GSC::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/GSC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .LoV::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/LoV.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .AM::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 55%;
  width: 100%;
  transform: translateY(-75px);
  height: 85px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .necromunda::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 45%;
  width: 100%;
  transform: translateY(-75px);
  height: 175px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/necromunda.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .frame .basic::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-75px);
  height: 150px;
  opacity: 0.1;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .stratagem .stratagem_footer {
  border-top: 2px solid #000;
  position: absolute;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  bottom: 16px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
  background: #e2e2dd;
  z-index: 10;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 4px;
  width: calc(100% - 30px);
  left: 0;
}
.data-necromunda .data-necromunda .stratagem .stratagem_header {
  display: block;
  height: auto;
  margin: 0 8px;
}
.data-necromunda .data-necromunda .stratagem .stratagem_type {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 2px solid #000;
  text-transform: uppercase;
  text-align: center;
  margin-top: 8px;
}
.data-necromunda .data-necromunda .stratagem .stratagem_name {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin-top: 8px;
}
.data-necromunda .data-necromunda .stratagem .stratagem_description {
  margin: 8px 8px;
  margin-top: 16px;
  padding: 0 4px;
  vertical-align: middle;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  text-align: justify;
  letter-spacing: 0px;
  line-height: 14px;
}
.data-necromunda .data-necromunda .vehicle .page {
  padding: 8px;
  border-radius: 16px;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/necromunda-background.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.data-necromunda .data-necromunda .vehicle .card {
  height: 75mm;
  width: 110mm;
}
.data-necromunda .data-necromunda .vehicle .large {
  height: 90mm;
  width: 130mm;
}
.data-necromunda .data-necromunda .vehicle .header {
  padding: 10px;
  height: 48px;
  border-top-left-radius: 8px;
  background-color: #ffffff88;
}
.data-necromunda .data-necromunda .vehicle .header .name {
  font-size: 20px;
  font-family: var(--necromunda-font-family);
  letter-spacing: 1px;
  text-transform: uppercase;
}
.data-necromunda .data-necromunda .vehicle .header .type {
  font-size: 16px;
  font-family: var(--necromunda-font-family);
  letter-spacing: 1px;
  margin-left: 8px;
}
.data-necromunda .data-necromunda .vehicle .cost {
  top: -4px;
  position: relative;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/necromunda-gear.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  text-align: center;
  padding-top: 8px;
  font-family: var(--necromunda-font-family);
  letter-spacing: 1px;
  text-transform: uppercase;
}
.data-necromunda .data-necromunda .vehicle .cost::after {
  position: absolute;
  width: 100%;
  content: "CREDITS";
  font-size: 9px;
  text-align: center;
  left: 0;
  bottom: 10px;
}
.data-necromunda .data-necromunda .vehicle .divider {
  margin-top: 6px;
}
.data-necromunda .data-necromunda .vehicle .wargear {
  display: grid;
  grid-template-columns: 2fr 10fr;
  column-gap: 4px;
  font-size: 10px;
  margin-top: 8px;
}
.data-necromunda .data-necromunda .vehicle .wargear p {
  margin-bottom: 0px;
}
.data-necromunda .data-necromunda .vehicle .wargear .title {
  padding-left: 4px;
  background-color: #ffffff88;
  text-transform: uppercase;
}
.data-necromunda .data-necromunda .vehicle .wargear .options {
  padding-left: 4px;
  background-color: #ffffff88;
}
.data-necromunda .data-necromunda .vehicle .rules {
  display: grid;
  grid-template-columns: 2fr 10fr;
  column-gap: 4px;
  font-size: 10px;
  margin-top: 2px;
}
.data-necromunda .data-necromunda .vehicle .rules p {
  margin-bottom: 0px;
}
.data-necromunda .data-necromunda .vehicle .rules .title {
  padding-left: 4px;
  background-color: #ffffff88;
  text-transform: uppercase;
}
.data-necromunda .data-necromunda .vehicle .rules .options {
  padding-left: 4px;
  background-color: #ffffff88;
}
.data-necromunda .data-necromunda .vehicle .abilities {
  display: grid;
  grid-template-columns: 2fr 10fr;
  column-gap: 4px;
  font-size: 10px;
  margin-top: 2px;
}
.data-necromunda .data-necromunda .vehicle .abilities p {
  margin-bottom: 0px;
}
.data-necromunda .data-necromunda .vehicle .abilities .title {
  padding-left: 4px;
  background-color: #ffffff88;
  text-transform: uppercase;
}
.data-necromunda .data-necromunda .vehicle .abilities .options {
  padding-left: 4px;
  background-color: #ffffff88;
}
.data-necromunda .data-necromunda .vehicle .weapons .legend {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 5fr;
  column-gap: 2px;
  font-size: 10px;
  font-weight: 600;
  padding-right: 2px;
  margin-top: 6px;
}
.data-necromunda .data-necromunda .vehicle .weapons .legend div {
  text-align: center;
}
.data-necromunda .data-necromunda .vehicle .weapons .legend :nth-child(1) {
  text-align: left;
  padding-left: 4px;
  border-right: 1px solid black;
}
.data-necromunda .data-necromunda .vehicle .weapons .legend :nth-child(3) {
  border-right: 1px solid black;
}
.data-necromunda .data-necromunda .vehicle .weapons .legend :nth-child(5) {
  border-right: 1px solid black;
}
.data-necromunda .data-necromunda .vehicle .weapons .legend :last-child {
  text-align: left;
  border-left: 1px solid black;
  padding-left: 4px;
}
.data-necromunda .data-necromunda .vehicle .weapons .line {
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 5fr;
  column-gap: 2px;
  font-size: 10px;
  padding-right: 2px;
  padding-top: 2px;
}
.data-necromunda .data-necromunda .vehicle .weapons .line div {
  background-color: #ffffffaa;
  text-align: center;
}
.data-necromunda .data-necromunda .vehicle .weapons .line :nth-child(1) {
  text-align: left;
  border-right: 1px solid black;
  padding-right: 8px;
  padding-left: 4px;
}
.data-necromunda .data-necromunda .vehicle .weapons .line :nth-child(3) {
  border-right: 1px solid black;
}
.data-necromunda .data-necromunda .vehicle .weapons .line :nth-child(5) {
  border-right: 1px solid black;
}
.data-necromunda .data-necromunda .vehicle .weapons .line :last-child {
  text-align: left;
  border-left: 1px solid black;
  padding-left: 4px;
}
.data-necromunda .data-necromunda .vehicle .datasheet .categories {
  display: grid;
  grid-template-columns: 1fr 6fr 5fr;
  font-size: 10px;
  height: 11px;
}
.data-necromunda .data-necromunda .vehicle .datasheet .categories :nth-child(1) {
  border-right: 2px solid #a05236;
}
.data-necromunda .data-necromunda .vehicle .datasheet .categories :nth-child(2) {
  text-align: center;
  border-right: 2px solid #a05236;
}
.data-necromunda .data-necromunda .vehicle .datasheet .categories :nth-child(3) {
  text-align: center;
}
.data-necromunda .data-necromunda .vehicle .datasheet .legend {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 5fr;
  border-bottom: 2px solid #a05236;
  font-size: 11px;
}
.data-necromunda .data-necromunda .vehicle .datasheet .legend .movement {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 8px;
  text-align: center;
  border-right: 2px solid #a05236;
  color: darkred;
  font-weight: 600;
  padding-right: 2px;
}
.data-necromunda .data-necromunda .vehicle .datasheet .legend .toughness {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2px;
  text-align: center;
  border-right: 2px solid #a05236;
  color: darkred;
  font-weight: 600;
  font-size: 9px;
  padding-right: 2px;
  padding-top: 2px;
}
.data-necromunda .data-necromunda .vehicle .datasheet .legend .stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 8px;
  text-align: center;
  border-right: 2px solid #a05236;
  color: darkred;
  font-weight: 600;
  padding-right: 2px;
}
.data-necromunda .data-necromunda .vehicle .datasheet .legend .crew {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 8px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  color: darkred;
  font-weight: 600;
  background-color: #c2ada399;
}
.data-necromunda .data-necromunda .vehicle .datasheet .line {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 5fr;
  font-size: 11px;
}
.data-necromunda .data-necromunda .vehicle .datasheet .line div {
  background-color: #ffffff66;
  text-align: center;
}
.data-necromunda .data-necromunda .vehicle .datasheet .line .movement {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2px;
  text-align: center;
  border-right: 2px solid #a05236;
  padding-right: 2px;
}
.data-necromunda .data-necromunda .vehicle .datasheet .line .toughness {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2px;
  text-align: center;
  border-right: 2px solid #a05236;
  padding-right: 2px;
}
.data-necromunda .data-necromunda .vehicle .datasheet .line .stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2px;
  text-align: center;
  border-right: 2px solid #a05236;
  padding-right: 2px;
}
.data-necromunda .data-necromunda .vehicle .datasheet .line .crew {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
}
