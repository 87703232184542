.movement {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Move.svg");
}

.weaponskill {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/WeaponSkill.svg");
}

.ballisticskill {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/BallisticSkill.svg");
}

.toughness {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Toughness.svg");
}

.wounds {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Wounds.svg");
}

.attacks {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Attacks.svg");
}

.leadership {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Leadership.svg");
}

.save {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Save.svg");
}

.inv {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Invuln.svg");
}

.ap {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/ArmourPenetration.svg");
}

.dmg {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Damage.svg");
}

.range {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Range.svg");
}

.strength {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Strength.svg");
}

.type {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Type.svg");
}

.assault-weapon {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Assault.svg");
}

.grenade {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Grenade.svg");
}

.dakka {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/RapidFire.svg");
}

.heavy-weapon {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Heavy.svg");
}

.melee {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Melee.svg");
}

.pistol {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Pistol.svg");
}

.rapid-fire {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/RapidFire.svg");
}

.user {
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/User.svg");
}
