&.playingcard {
  height: 8.89cm;
  width: 5.71cm;
}
&.card {
  height: 10.8cm;
  width: 7.77cm;
}
&.poker {
  height: 8.89cm;
  width: 6.35cm;
}
&.sheet {
  height: 209.5mm;
  width: 148mm;
}
&.a4 {
  height: 297mm;
  width: 210mm;
}
&.letter {
  height: 279mm;
  width: 216mm;
}
&.letter-half {
  height: 216mm;
  width: 139mm;
}
&.secondary {
  height: 13.76cm;
  width: 7.77cm;
}