@import "./factions.less";

.list-item {

  each(@factions, {
    .@{value}::before {
      content: " ";
      display: block;
      position: absolute;
      width: 32px;
      height: 32px;
      opacity: 0.3;
      right: 8px;
      margin-top: -4px;
      background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/@{value}.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  });
}

.data-40k,
.data-basic {
  &.no-icons {
    .edit_heading {
      .label {
        @import (multiple) "./40k-no-icons.less";
      }
    }
  }

  &.icons {
    .edit_heading {
      .label {
        .icon {
          height: 11px;
          width: 11px;
          margin-right: 2px;
          margin-top: -2px;
          background-repeat: no-repeat;
        }
        @import (multiple) "./40k-icons.less";
      }
    }
  }

  .unit {
    .page {
      width: inherit;
      display: grid;
      position: relative;
      padding: 8px;
      background-color: #d0d0d0;

      &.no-icons {
        @import (multiple) "./40k-no-icons.less";
      }

      &.icons {
        @import (multiple) "./40k-icons.less";
      }

      @import (multiple) "./sizes.less";

      .value {
        vertical-align: middle;
        font-family: "Heebo", sans-serif;
        font-size: 12px;
        letter-spacing: 0px;
      }
      .frame {
        display: block;
        height: 100%;
        width: 100%;
        background: black;
        border-radius: 13.5px;
        background-color: #e2e2dd;
        overflow: hidden;
        .background {
          width: inherit;
        }
        each(@factions, {
          .@{value}::before {
            content: " ";
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            transform: translateY(-75px);
            height: 150px;
            opacity: 0.1;
            background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/@{value}.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
        });

        .AM::before {
          content: " ";
          display: block;
          position: absolute;
          left: 0;
          top: 55%;
          width: 100%;
          transform: translateY(-75px);
          height: 85px;
          opacity: 0.1;
          background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .basic::before {
          content: " ";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: 100%;
          transform: translateY(-75px);
          height: 150px;
          opacity: 0.1;
          background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .header {
          display: block;
          height: 45px;
          margin: 0 8px;

          .name {
            float: left;
            width: calc(100% - 44px);
            min-height: 35px;
            padding-top: 8px;
            line-height: 15px;
            text-transform: uppercase;
            vertical-align: middle;
            font-family: "Heebo", sans-serif;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -1px;
            border-bottom: 2px solid #000;
          }

          .role {
            position: relative;
            float: left;
            width: 35px;
            height: 35px;
            padding-top: 8px;
            margin-right: 8px;

            div {
              height: 35px;
              width: 35px;
              position: absolute;
              bottom: 0;
            }
            .hq {
              background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/HQ.svg");
            }

            .troops {
              background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Troops.svg");
            }
            .elites {
              background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Elites.svg");
            }

            .fast-attack {
              background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Fast-Attack.svg");
            }

            .dedicated-transport {
              background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Dedicated-Transport.svg");
            }

            .flyer {
              background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Flyer.svg");
            }

            .heavy-support {
              background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Heavy-Support.svg");
            }

            .fortification {
              background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Fortification.svg");
            }

            .lord-of-war {
              background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Lord-of-War.svg");
            }
            .unknown {
              background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/svg/Unknown.svg");
            }
          }
        }
        .description {
          background: #dee3e0;
          margin: 4px 5px;
          padding: 0 4px;
          vertical-align: middle;
          font-family: "Heebo", sans-serif;
          font-size: 12px;
          font-weight: 700px;
          text-align: justify;
          letter-spacing: 0px;
          word-spacing: -1px;
          line-height: 14px;

          table {
            width: 100%;
          }

          table th {
            border: 1px solid #777273;
            padding: 4px;
            background-color: #b6beba;
          }

          table td {
            border: 1px solid #777273;
            padding: 4px;
          }

          p {
            margin-bottom: 0;
          }
        }
        .abilities {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .profile {
          border-bottom: 2px solid #777273;
          margin: 0 8px;
          .weapon_name {
            font-family: "Heebo", sans-serif;
            font-size: 12px;
            font-weight: 600;
            margin-left: 4px;
          }
          .weapon {
            &.nested {
              padding-left: 8px;
            }
            &:nth-of-type(even) {
              background: #dee3e0;
            }

            &:nth-of-type(even) + .weapon_desc {
              background: #dee3e0;
            }

            &:nth-of-type(odd) + .weapon_desc {
              background: #fff;
            }

            &:nth-of-type(odd) {
              background: #fff;
            }

            .weapon_profile {
              display: grid;
              grid-template-columns: 4fr 2fr 2fr 1fr 1fr 1fr;
              font-size: 11px;

              .weapon-icon {
                height: 11px;
                width: 11px;
                margin-right: 2px;
                margin-top: -2px;
                background-repeat: no-repeat;
              }
            }

            .weapon_desc {
              padding: 0 12px;
              margin-bottom: 4px;
              vertical-align: middle;
              font-family: "Heebo", sans-serif;
              font-size: 12px;
              font-weight: 700px;
              text-align: justify;
              letter-spacing: 0px;
              word-spacing: 0px;
              line-height: 14px;

              p {
                margin-bottom: 0px;
              }
            }
          }
          .statline {
            display: grid;
            width: 9fr;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            text-align: centre;
            vertical-align: middle;
            font-weight: 600;

            &:nth-child(even) {
              background: #dee3e0;
            }

            &:nth-child(odd) {
              background: #fff;
            }
          }
        }
        .labels {
          font-weight: 600;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

          .label {
            div {
              background-position: center;
            }
          }
        }
        .weapons {
          grid-template-columns: 4fr 2fr 2fr 1fr 1fr 1fr;
          font-size: 12px;
        }
        .heading {
          display: grid;
          background: #b6beba;
          margin: 4px 8px 0 8px;
          grid-template-rows: 14px;
          vertical-align: middle;
          border-bottom: 2px solid #777273;

          .icon {
            display: block;
            height: 11px;
            width: 11px;
            margin: 0 auto;
            background-repeat: no-repeat;
          }
        }
        .footer {
          position: absolute;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 700;
          text-align: center;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 4.5em;
          background: #e2e2dd;
          z-index: 10;
          bottom: 0;
          transform: translateX(-50%) translateY(-10px);
          left: 50%;
          width: calc(100% - 32px);
        }
      }
    }
  }
  .stratagem {
    .page {
      width: inherit;
      display: grid;
      position: relative;
      padding: 8px;
      background-color: #d0d0d0;

      @import (multiple) "./sizes.less";

      .frame {
        display: block;
        height: 100%;
        width: 100%;
        background: black;
        border-radius: 13.5px;
        background-color: #e2e2dd;
        overflow: hidden;
        .background {
          width: inherit;
        }
        each(@factions, {
        .@{value}::before {
          content: " ";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: 100%;
          transform: translateY(-75px);
          height: 150px;
          opacity: 0.1;
          background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/@{value}.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      });

        .AM::before {
          content: " ";
          display: block;
          position: absolute;
          left: 0;
          top: 55%;
          width: 100%;
          transform: translateY(-75px);
          height: 85px;
          opacity: 0.1;
          background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .basic::before {
          content: " ";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: 100%;
          transform: translateY(-75px);
          height: 150px;
          opacity: 0.1;
          background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .footer {
          border-top: 2px solid #000;
          position: absolute;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          bottom: 8px;
          text-align: center;
          padding-left: 15px;
          padding-right: 15px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 4.5em;
          background: #e2e2dd;
          z-index: 10;
          margin-left: 15px;
          margin-right: 15px;
          margin-bottom: 4px;
          width: calc(100% - 30px);
          left: 0;
        }

        .header {
          display: block;
          height: auto;
          margin: 0 8px;
        }

        .type {
          font-family: "Heebo", sans-serif;
          font-size: 12px;
          font-weight: 600;
          border-bottom: 2px solid #000;
          text-transform: uppercase;
          text-align: center;
          margin-top: 8px;
        }
        .name {
          font-family: "Heebo", sans-serif;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center;
          margin-top: 8px;
        }

        .description {
          margin: 8px 8px;
          margin-top: 4px;
          padding: 0 4px;
          vertical-align: middle;
          font-family: "Heebo", sans-serif;
          font-size: 13px;
          text-align: justify;
          letter-spacing: 0px;
          line-height: 14px;

          p {
            margin-bottom: 4px;
          }

          ul {
            padding-left: 16px;

            p {
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }

  .secondary {
    .page {
      width: inherit;
      display: grid;
      position: relative;
      padding: 8px;
      background-color: #d0d0d0;

      @import (multiple) "./sizes.less";

      .frame {
        display: block;
        height: 100%;
        width: 100%;
        background: black;
        background-color: #e2e2dd;
        overflow: hidden;
        border-radius: 13.5px;
        .background {
          width: inherit;
        }
        each(@factions, {
        .@{value}::before {
          content: " ";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: 100%;
          transform: translateY(-75px);
          height: 150px;
          opacity: 0.1;
          background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/@{value}.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
          .secondary_icon_@{value}::before {
            content: " ";
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 23px;
            height: 23px;
            opacity: 0.75;
            background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/@{value}.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
      });

        .AM::before {
          content: " ";
          display: block;
          position: absolute;
          left: 0;
          top: 55%;
          width: 100%;
          transform: translateY(-75px);
          height: 85px;
          opacity: 0.1;
          background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .basic::before {
          content: " ";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: 100%;
          transform: translateY(-75px);
          height: 150px;
          opacity: 0.1;
          background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .footer {
          border-top: 2px solid #000;
          position: absolute;
          text-transform: uppercase;
          font-size: 13px;
          font-weight: 600;
          text-align: center;
          bottom: 6px;
          padding-left: 15px;
          padding-right: 15px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 4.5em;
          background: #e2e2dd;
          z-index: 10;
          margin-left: 15px;
          margin-right: 15px;
          margin-bottom: 4px;
          width: calc(100% - 30px);
          left: 0;
        }

        .header {
          display: block;
          height: auto;
          margin: 0 8px;
        }

        .type {
          font-family: "Heebo", sans-serif;
          font-size: 12px;
          font-weight: 600;
          border-bottom: 2px solid #000;
          text-transform: uppercase;
          text-align: center;
          margin-top: 8px;
        }
        .name {
          font-family: "Heebo", sans-serif;
          font-size: 15px;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center;
          margin-top: 4px;
        }

        .description {
          margin: 8px 8px;
          margin-top: 4px;
          padding: 0 4px;
          vertical-align: middle;
          font-family: "Heebo", sans-serif;
          font-size: 13px;
          text-align: justify;
          letter-spacing: 0px;
          line-height: 14px;

          table {
            width: 100%;
          }

          table th {
            border: 1px solid #777273;
            padding: 4px;
            background-color: #b6beba;
            font-size: 0.8em;

            overflow: hidden;
            text-overflow: ellipsis;
          }

          table td {
            border: 1px solid #777273;
            padding: 4px;
          }

          p {
            margin-bottom: 4px;
          }

          ul {
            padding-left: 16px;
            li {
              padding-left: -8px;
            }
            p {
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
  .power {
    .page {
      width: inherit;
      display: grid;
      position: relative;
      padding: 8px;
      background-color: #d0d0d0;

      @import (multiple) "./sizes.less";
      .frame {
        display: block;
        height: 100%;
        width: 100%;
        background: black;
        background-color: #e2e2dd;
        overflow: hidden;
        border-radius: 13.5px;

        .page {
          display: grid;
          position: relative;
          padding: 8px;
        }

        .header {
          display: block;
          height: auto;
          margin: 0 8px;
        }

        .type {
          font-family: "Heebo", sans-serif;
          font-size: 12px;
          font-weight: 600;
          border-bottom: 2px solid #000;
          text-transform: uppercase;
          text-align: center;
          margin-top: 8px;
        }
        .name {
          font-family: "Heebo", sans-serif;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center;
          margin-top: 8px;
        }

        .description {
          margin: 8px 8px;
          margin-top: 4px;
          padding: 0 4px;
          vertical-align: middle;
          font-family: "Heebo", sans-serif;
          font-size: 13px;
          text-align: justify;
          letter-spacing: 0px;
          line-height: 14px;

          table {
            width: 100%;
          }

          table th {
            border: 1px solid #777273;
            padding: 4px;
            background-color: #b6beba;
            font-size: 0.8em;

            overflow: hidden;
            text-overflow: ellipsis;
          }

          table td {
            border: 1px solid #777273;
            padding: 4px;
          }

          p {
            margin-bottom: 4px;
          }

          ul {
            padding-left: 16px;

            p {
              margin-bottom: 2px;
            }
          }
        }
        .background {
          width: inherit;
        }
        each(@factions, {
        .@{value}::before {
          content: " ";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: 100%;
          transform: translateY(-75px);
          height: 150px;
          opacity: 0.1;
          background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/@{value}.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      });

        .AM::before {
          content: " ";
          display: block;
          position: absolute;
          left: 0;
          top: 55%;
          width: 100%;
          transform: translateY(-75px);
          height: 85px;
          opacity: 0.1;
          background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/AM.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .basic::before {
          content: " ";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: 100%;
          transform: translateY(-75px);
          height: 150px;
          opacity: 0.1;
          background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .footer {
          border-top: 2px solid #000;
          position: absolute;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          bottom: 8px;
          text-align: center;
          padding-left: 15px;
          padding-right: 15px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 4.5em;
          background: #e2e2dd;
          z-index: 10;
          margin-left: 15px;
          margin-right: 15px;
          margin-bottom: 4px;
          width: calc(100% - 30px);
          left: 0;
        }
      }
    }
  }
}
