@font-face {
  font-family: "ConduitITC";
  src: url("../../../fonts/Conduit ITC Regular.otf");
}
@font-face {
  font-family: "ConduitITC";
  src: url("../../../fonts/Conduit ITC Bold.otf");
  font-weight: bold; /*config its weight*/
}
@font-face {
  font-family: "ConduitITC";
  src: url("../../../fonts/Conduit ITC Italic.otf");
  font-style: italic;
}

@font-face {
  font-family: "PassionOne";
  src: url("../../../fonts/PassionOne-Regular.ttf");
}
@font-face {
  font-family: "PassionOne";
  src: url("../../../fonts/PassionOne-Bold.ttf");
  font-weight: bold; /*config its weight*/
}
.flex {
  display: grid;
}

.slide-up {
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.list-select {
  font-size: 0.65rem;
  border: 1px solid #001529;
  color: black;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 6px;
  text-align: center;
  float: right;
  margin-right: 8px;
  background: none;

  &:hover {
    background-color: #001529;
    color: white;
    cursor: pointer;
  }
  &:active {
    background-color: #003d75;
    color: white;
    cursor: pointer;
  }
}

.list-cost {
  font-size: 0.65rem;
  border: 1px solid #001529;
  color: black;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 6px;
  text-align: center;
  float: right;
  margin-right: 8px;
  background: none;
}

.list-category {
  border-bottom: 1px solid lightgray !important;
  width: 100%;
  background-color: #d3d3d36e;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .icon {
    font-size: 1rem;
  }
  .name {
    font-weight: 600;
    font-size: 1rem;
    padding-left: 8px !important;
  }
}

.mobile-faction {
  .title {
    .heading {
      text-align: center;
      background-color: var(--header-colour);
      padding-left: 8px;

      .title {
        text-transform: uppercase;
        color: white;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        z-index: 2;
      }
    }
  }
  .enhancements {
    .heading {
      background-color: var(--header-colour);
      padding-left: 8px;

      .title {
        text-transform: uppercase;
        color: white;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        z-index: 2;
      }
    }
  }
  .stratagems {
    .heading {
      background-color: var(--header-colour);
      padding-left: 8px;

      .title {
        text-transform: uppercase;
        color: white;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        z-index: 2;
      }
    }
  }
}

.mobile-icon-button {
  span {
    font-size: 22px !important;
  }
}
.mobile-menu,
.desktop-list {
  .ant-list-item {
    &:hover {
      background-color: rgb(231, 231, 231);
    }
    &.selected {
      background-color: #0070d6;
      span {
        font-weight: 600;
        color: white;
      }
      &:hover {
        background-color: #0079eb;
      }
    }
    &:active {
      background-color: #0082fc;
      span {
        font-weight: 600;
        color: white;
      }
    }
    &.disabled {
      background-color: #f5f5f5;
      span {
        font-weight: 200;
        color: #00000040;
      }
    }
  }
}

.bottom-nav {
  .bottom-nav-item {
    background-color: #001529;
    color: rgb(165, 165, 165);
    font-size: 1.5rem;
    transition: font-size 0.1s ease, color 0.1s ease;

    &.active {
      font-size: 1.7rem;
      color: white;
      background-color: #04223e;
    }
  }
}

.nav-menu-item {
  display: flex;
  justify-content: center;
  height: calc(64px);
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;

  &.selected {
    border-bottom: 3px solid #0070d6;
  }

  &:hover {
    border-bottom: 3px solid white;
  }
}

.welcome-background {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000aa;
}

.modal-background {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000aa;
}

.welcome-container {
  border-radius: 4px;
  width: 1000px;
  height: 780px;
  position: absolute;
  background: white;
  top: 45%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.whatsnew-container {
  border-radius: 4px;
  width: 800px;
  position: absolute;
  background: white;
  top: 45%;
  left: 50%;
  padding-bottom: 48px;
  transform: translateX(-50%) translateY(-50%);
}

.factionsettings-container {
  border-radius: 4px;
  width: 800px;
  position: absolute;
  background: white;
  top: 45%;
  left: 50%;
  padding-bottom: 0px;
  transform: translateX(-50%) translateY(-50%);
}

.welcome-cover {
  height: "510px";
}

.welcome-cover::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 90px;
  width: 100%;
  height: calc(100% - 180px);
  opacity: 0.05;
  background-image: url("https://raw.githubusercontent.com/ronplanken/40k-Data-Card/master/src/dc/logo_large.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.labels {
  font-weight: 600;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.edit_heading {
  display: grid;
  background: #b6beba;
  grid-template-rows: 14px;
  vertical-align: middle;
  border-bottom: 2px solid #777273;
}

.edit_line {
  display: grid;
  background: #b6beba;
  grid-template-rows: 32px;
  vertical-align: middle;
  border-bottom: 2px solid #777273;
}

.weapon_edit_profile {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 11px;
}
.ganger_weapon_profile {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 11px;
}

.center {
  display: grid;
  place-items: center;
  justify-content: center;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

.ant-form-small {
  .ant-form-item {
    margin-bottom: 16px;
  }
}

.tree-item-container {
  &.selected {
    .tree-state {
      padding-left: 5px;
      padding-top: 2px;
      width: 24px;
    }
  }
  .tree-state {
    padding-left: 9px;
    padding-top: 2px;
    width: 24px;
  }
}

.vertical-resizer {
  width: 2px;
  background-color: rgba(0, 0, 0, 0.06);

  transition: background-color 0.25s linear;

  &:hover {
    background-color: grey;
    width: 2px;
  }
}
.horizontal-resizer {
  height: 2px;
  background-color: rgba(0, 0, 0, 0.06);

  transition: background-color 0.25s linear;

  &:hover {
    background-color: grey;
    height: 2px;
  }
}
