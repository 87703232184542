.movement::after {
  content: "M";
  font-size: 11px;
  display: block;
  margin-top: -2px;
}

.weaponskill::after {
  content: "WS";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}

.ballisticskill::after {
  content: "BS";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}

.toughness::after {
  content: "T";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}

.wounds::after {
  content: "W";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}

.attacks::after {
  content: "A";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}

.leadership::after {
  content: "Ld";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}

.save::after {
  content: "Sv";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}

.inv::after {
  content: "Inv";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}

.ap::after {
  content: "AP";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}

.dmg::after {
  content: "D";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}

.range::after {
  content: "Rng";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}

.strength::after {
  content: "S";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  text-align: center;
}

.type::after {
  content: "Type";
  font-size: 11px;
  display: block;
  margin-top: -2px;
  margin-left: -8px;
  text-align: center;
}

.assault-weapon::after {
  content: "As";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}

.grenade::after {
  content: "Gr";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}

.dakka::after {
  content: "Dk";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}

.heavy-weapon::after {
  content: "Hv";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}

.melee::after {
  content: "Me";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}

.pistol::after {
  content: "Ps";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}

.rapid-fire::after {
  content: "Rf";
  font-size: 12px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}

.user::after {
  content: "Us";
  font-size: 11px;
  display: block;
  margin-left: -4px;
  padding-right: 2px;
  text-align: center;
}
