// html {
//   // font-size: 17px !important;

.viewer {
  .ant-list-item,
  .ant-select,
  .ant-select-item-option-content,
  .ant-collapse,
  .ant-drawer-title {
    font-size: 1rem;
  }
  .weapon {
    .line {
      .value {
        font-size: 1rem !important;
      }
    }
  }
  .special {
    .description {
      padding-left: 4px !important;
      font-size: 0.9rem !important;
    }
    .ability {
      padding-left: 8px !important;
      .description {
        padding-left: 4px !important;
        font-size: 0.9rem !important;
      }
    }
  }
  .damaged {
    .description {
      font-size: 0.9rem !important;
    }
  }
  .keywords {
    .value {
      font-size: 0.8rem !important;
    }
  }
  .factions {
    .value {
      font-size: 0.8rem !important;
    }
  }
  .ability {
    .description,
    .value,
    .name {
      font-size: 0.9rem !important;
    }
    .title {
      padding-right: 4px;
      font-size: 0.9rem !important;
    }
  }
}
